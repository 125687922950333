import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../Services/login.service';
import { GroupService } from '../../Services/group.service';
import { Location } from '@angular/common';
import swal from 'sweetalert2';

import * as _ from 'lodash';
import { SharedService } from 'src/app/Services/sharedService';
import { ProjectService } from 'src/app/Services/project.service';
import { UserListComponent } from '../user-list/user-list.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { passwordValidator } from 'src/app/password-validators';

@Component({
	selector: 'app-create-user',
	templateUrl: './create-user.component.html',
	styleUrls: ['./create-user.component.css']
})

export class CreateUserComponent implements OnInit {
	adminId;
	isDisable = false;
	createUserForm: UntypedFormGroup;
	groupId: any = [];
	groups: any;
	loading = false;
	isEdit;
	gID;
	roles: any = [
		"Admin",
		"Employee"
	];
	selectedRole2 = '';
	hide = true;
	hideConfirm: boolean = true;
	isEditData: any;
	isResponseGet: any = true;
	selectedValue = '';
	userEmail = '';
	emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

	@Input()
	get isEditable() {
		return this.isEditData;
	}
	set isEditable(data: any) {
		this.isEditData = data;
	}


	titleMsg: any = "Add New User"
	constructor(private loginService: LoginService,
		private router: Router,
		private _route: ActivatedRoute,
		private _groupService: GroupService,
		private _location: Location,
		private _sharedService: SharedService,
		private _projectService: ProjectService,
		private _snackBar: MatSnackBar
	) {

		this.isEdit = this._sharedService.isEdit;
		this.createNewForm();

		this._projectService.editUserData.subscribe((value: any) => {
			this.gID = value.editUserId;
			this.isEdit = value.isUserEditable

			if (!this.isEdit) {
				this.createNewForm();
			} else {
				this.editFormFill();
			}
		})
	}

	passwordMatchValidator(form: UntypedFormGroup) {
		return form.get('password').value === form.get('confirmPassword').value ? null : { 'mismatch': true };
	}

	createNewForm() {
		this.createUserForm = new UntypedFormGroup({
			name: new UntypedFormControl(''),
			username: new UntypedFormControl(''),
			first_name: new UntypedFormControl('', [Validators.required]),
			last_name: new UntypedFormControl('', [Validators.required]),
			email: new UntypedFormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
			password: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(20), passwordValidator()]),
			confirmPassword: new UntypedFormControl('', [Validators.required]),
			send_email: new UntypedFormControl(false),
			isRandomEmail: new UntypedFormControl(false),
			roles2: new UntypedFormControl('', [Validators.required]),
		}, { validators: this.passwordMatchValidator });

		this._route.params.subscribe((params) => {
			this.groupId.push(params.groupId);
		});
		if (this.isEdit == true) {
			this._projectService.getEmployeeData(this.gID).subscribe((res: any) => {

				let name = res.name.split(" ");
				this.userEmail = res.email;
				let first_name = "";
				let last_name = "";
				first_name = res.first_name;
				last_name = res.last_name;
				this.createUserForm.controls['first_name'].setValue(first_name);
				this.createUserForm.controls['last_name'].setValue(last_name);

				this.createUserForm = new UntypedFormGroup({
					first_name: new UntypedFormControl(first_name, [Validators.required]),
					last_name: new UntypedFormControl(last_name, [Validators.required]),
					roles2: new UntypedFormControl(res.role[0] == 'employee' && res.role.length > 1 ? res.role[1] : 'employee', [Validators.required]),
				});
			})
		}
	}

	editFormFill() {
		this._projectService.getEmployeeData(this.gID).subscribe((res: any) => {

			let name = res.name.split(" ");
			this.userEmail = res.email;
			let first_name = "";
			let last_name = "";
			first_name = res.first_name;
			last_name = res.last_name;
			this.createUserForm.controls['first_name'].setValue(first_name);
			this.createUserForm.controls['last_name'].setValue(last_name);

			this.createUserForm = new UntypedFormGroup({
				first_name: new UntypedFormControl(first_name, [Validators.required]),
				last_name: new UntypedFormControl(last_name, [Validators.required]),
				roles2: new UntypedFormControl(res.role[0] == 'employee' && res.role.length > 1 ? res.role[1] : 'employee', [Validators.required]),
			});
		})
	}
	ngOnChanges(changes: SimpleChanges) {
		if (!this.isEdit) {
			if (this.createNewForm) {
				this.createUserForm.reset();
			}
			this.createNewForm();
		}
		let data = changes.isEditable.currentValue;

		if (data.isEdit == true) {

		}
		else {
			this.blankValueInInputs();
			this.isEdit = data.isEdit;
			this.titleMsg = "Edit User";
		}
	}

	ngOnInit() {
		this.adminId = JSON.parse(localStorage.getItem('logedInUser'))._id;
	}

	createNewUser() {
		this.isResponseGet = false;
		this.isDisable = true
		if (!(this.createUserForm.get('password').value == this.createUserForm.get('confirmPassword').value)) {
			this.isResponseGet = true;
			swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Password and confirm password must be same',
			})
			this.isResponseGet = true;
			this.isDisable = false;
			return;

		}

		const formData = this.createUserForm.getRawValue();
		this.createUserForm.value.email = this.createUserForm.value.email ? this.createUserForm.value.email : formData.email

		this.createUserForm.value.name = this.createUserForm.value.first_name + " " + this.createUserForm.value.last_name;
		this.createUserForm.value.username = this.createUserForm.value.email;

		if (!this.createUserForm.valid) {
			this.isDisable = false;
			return;
		}

		this.loginService.addUser(this.createUserForm.value).subscribe(checkAddUser => {
			this.isDisable = false;
			swal.fire({
				position: 'center',
				icon: 'success',
				title: 'Employee created',
				html: `<p>
				<strong>Email: </strong>
				<b>${formData.email}</b> 
				</p>`,
				showConfirmButton: false,
				timer: 5000,
			}).then(() => {
				this.blankValueInInputs();
				this.isResponseGet = true;
			})
			this.blankValueInInputs();
			this._projectService.updateEmployeeList({})

			if (this._sharedService.modalinstance) {

				this._sharedService.modalinstance.close();
			}
			this.createUserForm.reset();
		}, error => {
			this.isDisable = false;
			swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: `${error.error.message}`,
				showConfirmButton: false,
				timer: 3000
			})
			this.isResponseGet = true;
		});

	}

	createUser() {
		this.loading = true;
		this.isResponseGet = false;
		if (!this.createUserForm.valid) {
			return;
		}
		Object.assign(this.createUserForm.value, { admin: this.adminId });
		this.loginService.addUser(this.createUserForm.value).subscribe(checkAddUser => {
			this.loading = false;
			swal.fire({
				position: 'center',
				icon: 'success',
				title: 'Employee created',
				showConfirmButton: false,
				timer: 3000,
			})
			this.isResponseGet = true;
			if (this.groupId && this.groupId[0] != 'all') {
				this.router.navigate(['/employees', this.groupId[0]]);

			}
			else {
				this.router.navigate(['/projects']);

			}
			this.createUserForm.reset();

		}, (error) => {
			this.loading = false;
			swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: `${error.error.message}`,
				showConfirmButton: false,
				timer: 3000,
			})
			this.isResponseGet = true;
			this.isResponseGet = true;
		});

	}
	changeAnotherRole(value) {
		const currentRole = this.createUserForm.get('roles2').value;
		if (currentRole === this.selectedValue) {
			this.createUserForm.get('roles2').patchValue('')
		} else {
			this.createUserForm.get('roles2').patchValue(value)
		}
		this.selectedValue = value;
	}
	cancleCreateUser() {
		this.createUserForm.reset();
		this._location.back();
		this._sharedService.isEdit = false;
	}
	EditNewUser() {
		this.isResponseGet = false;
		if (!this.createUserForm.valid) {
			return;
		}

		this._projectService.editEmployee(this.gID, this.createUserForm.value).subscribe(data => {
			this.isResponseGet = true;
			this.isResponseGet = true;
			swal.fire({
				position: 'center',
				icon: 'success',
				title: 'Employee Updated Successfully',
				showConfirmButton: false,
				timer: 1500,
			})
			this._projectService.updateEmployeeList({})
			this._sharedService.isEdit = false;
			this._projectService.updateEmployeeList({})
			if (this._sharedService.modalinstance) {
				this._sharedService.modalinstance.close();
			}
			this.router.navigate(["/employees"]);
		}, err => {
			this._snackBar.open('Something went wrong', 'Undo', {
				duration: 3000
			})
		});
	}

	blankValueInInputs() {
		this.createUserForm.reset();
	}

	modalClose() {
		this.createUserForm.reset();
		if (this._sharedService.modalinstance) {
			this._sharedService.modalinstance.close();
		}
	}

	onRandomEmailChange(event: any): void {
		const isRandomEmail = event.checked;
		if (isRandomEmail) {
			const email = this.generateRandomEmail()
			this.createUserForm.get('email')?.setValue(email);
			this.createUserForm.get('send_email')?.disable();
			this.createUserForm.get('send_email')?.setValue(false);
			this.createUserForm.get('email')?.disable();

		} else {
			this.createUserForm.get('email')?.setValue('');
			this.createUserForm.get('send_email')?.enable();
			this.createUserForm.get('email')?.enable();
			this.createUserForm.get('send_email')?.setValue(false);
		}
	}
	generateRandomEmail(): string {
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const domainOptions = ['fakeemail.com', 'randommail.com', 'noemail.com', 'nowhere.com', 'doesnotexist.com', 'invalidemail.com', 'fakedomain.com', 'emailnotreal.com', 'emptyaddress.com'];
		const domain = domainOptions[Math.floor(Math.random() * domainOptions.length)];
		const usernameLength = 6;
		let username = '';
		for (let i = 0; i < usernameLength; i++) {
			const randomIndex = Math.floor(Math.random() * characters.length);
			username += characters[randomIndex];
		}
		return `${username}@${domain}`;
	}
}