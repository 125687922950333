import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { config } from './config';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private router: Router, private snackBar: MatSnackBar) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!navigator.onLine) {
      this.showSnackBar('Please check your internet connection');
      return throwError(() => new Error('No internet connection'));
    }

    const token = localStorage.getItem('token');
    const requestHeader = req.headers.get('authorization');
    const skipHeader = req.headers.get('skip');

    const shouldAttachToken =
      token &&
      !requestHeader &&
      !skipHeader &&
      req.url !== config.feedBackSheet &&
      !req.url.includes('https://s3.ap-south-1.amazonaws.com/');

    const modifiedRequest = shouldAttachToken
      ? req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) })
      : req;

    return next.handle(modifiedRequest).pipe(catchError(this.handleError.bind(this)));
  }

  private handleError(error: any): Observable<never> {
    switch (error.status) {
      case 401:
        this.handleUnauthorizedError();
        break;
      case 404:
        if (error.error?.code === 'rest_user_invalid_id') {
          this.handleInvalidUserIdError(error.error.message);
        }
        break;
      default:
        // Handle other error statuses if needed
        break;
    }
    return throwError(() => error);
  }

  private handleUnauthorizedError(): void {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  private handleInvalidUserIdError(message: string): void {
    this.router.navigate(['/employees']);
    this.showSnackBar(message);
  }

  private showSnackBar(message: string): void {
    this.snackBar.open(message, 'Undo', { duration: 3000 });
  }
}
