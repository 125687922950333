import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { colors } from '../colors';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  constructor() { }

  /**
   * Show a confirmation dialog with screenshot details.
   * @param imageInfo The image information to display
   * @param userName The name of the user associated with the screenshot
   */
  showScreenshotConfirmation(imageInfo: any, userName: string): Promise<SweetAlertResult> {
    return Swal.fire({
      title: 'Are you sure?',
      html: `
        <p>
          This screenshot was captured for: 
          <b>${userName}</b> 
          using <b>${imageInfo.app_name}</b> 
          on <b>${imageInfo.captured_time}</b>. 
          This action cannot be undone.
        </p>
      `,
      icon: 'warning',
      imageUrl: imageInfo.thumbnail, // Display the image in the dialog
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: 'Employee Screenshot',
      showCancelButton: true,
      confirmButtonColor: colors.purple,
      cancelButtonColor: colors.red,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  }

  /**
 * Show a confirmation dialog for removing all images.
 * @param count The total number of images to be removed
 */
showRemoveAllImagesConfirmation(count: number): Promise<SweetAlertResult> {
  return Swal.fire({
    title: 'Are you sure?',
    html: `
      <p>
        You are about to remove <b>${count}</b> images. 
        This action cannot be undone.
      </p>
    `,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: colors.purple,
    cancelButtonColor: colors.red,
    confirmButtonText: 'Yes, remove all!',
    cancelButtonText: 'Cancel',
  });
}

  /**
   * Show a simple alert.
   * @param title Title of the alert
   * @param text Text of the alert
   * @param icon Type of the alert icon ('success', 'error', 'warning', 'info', 'question')
   */
  showAlert(title: string, text: string, icon: 'success' | 'error' | 'warning' | 'info' | 'question'): Promise<SweetAlertResult> {
    return Swal.fire({
      title,
      text,
      icon,
      confirmButtonText: 'OK',
      confirmButtonColor: colors.purple
    });
  }

  /**
   * Show a confirmation dialog.
   * @param title Title of the dialog
   * @param text Text of the dialog
   * @param confirmButtonText Text for the confirm button
   * @param cancelButtonText Text for the cancel button
   */
  showConfirmation(
    title: string,
    text: string,
    confirmButtonText = 'Yes',
    cancelButtonText = 'No'    
  ): Promise<SweetAlertResult> {
    return Swal.fire({
      title,
      text,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText
    });
  }

   /**
   * Show a toast notification.
   * @param title Title of the toast
   * @param icon Type of the toast icon ('success', 'error', 'warning', 'info', 'question')
   */
   showToast(title: string, icon: 'success' | 'error' | 'warning' | 'info' | 'question'): void {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true
    });

    Toast.fire({
      icon,
      title
    });
  }

  /**
   * Show a custom alert with advanced options.
   * @param options SweetAlert2 options
   */
  showCustomAlert(options: SweetAlertOptions): Promise<SweetAlertResult> {
    return Swal.fire(options);
  }

  showDownloadProcess(){
    Swal.fire({
      title: 'Download in Progress',
      html: 'Please wait while your file is being downloaded...',
      allowOutsideClick: false, // Prevent closing by clicking outside
      showConfirmButton: false, // Hide the confirm button
      didOpen: () => {
        Swal.showLoading(); // Show loading spinner
      }
    });
  }
}
