import {
  Component,
  OnInit,
  ChangeDetectorRef,
  SimpleChange,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LoginService } from "../../Services/login.service";
import { config } from "../../config";
import { Observable, Observer, Subscription } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import * as moment from "moment";
import * as _ from "lodash";
import { TooltipPosition } from "@angular/material/tooltip";
import { element } from "protractor";
import { timeStamp } from "console";
import {
  AngularFireDatabase,
  AngularFireList,
} from "@angular/fire/compat/database";
import { ProjectService } from "src/app/Services/project.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DisplayToastComponent } from "src/app/DisplayToast/display-toast/display-toast.component";
import { SharedService } from "src/app/Services/sharedService";
import { MessagingServiceService } from "src/app/Services/messaging-service.service";
import { MatPaginator } from "@angular/material/paginator";
import { DatePipe } from "@angular/common";
import { CustomTimeAgoPipe } from "src/app/time-ago.pipe";
import { HttpClient } from "@angular/common/http";
import swal from 'sweetalert2';
import { SweetAlertService } from "src/app/Services/sweet-alert.service";
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { TimeConversionService } from "src/app/Services/time-conversion.service";

declare var $;

class User {
  id: string;
  email: string;
  ssRequest: boolean;
  scRequestStart: boolean;
  scRequestStop: boolean;
  status: string;
  time: string;
  isreceive: boolean;
}
@Component({
  selector: "app-screen-shot-viwer",
  templateUrl: "./screen-shot-viwer.component.html",
  styleUrls: ["./screen-shot-viwer.component.css"],
  providers: [DatePipe],

})
export class ScreenShotViwerComponent implements OnInit {

  @ViewChild("EmpPaginator", { static: false }) EmpPaginator: MatPaginator;

  userId;
  pageSize = 25;
  offset = 1;
  today = new Date();
  userSSList;
  totalSS = 0;
  userSSListDuplicate: any;
  filePath = config.baseMediaUrl;
  paginationArray = [];
  isAdmin: boolean = false
  user: any;
  p: number = 1;
  maxDate = moment();
  statusObservable: Subscription;
  userStatus: any;
  loading = false;
  @Output() childEvent = new EventEmitter();
  private dbPath = localStorage.getItem('dbPath');

  @Input("userDetails") userDetails;
  @Input("startDate") startDateFilter: any;
  @Input("endDate") endDateFilter: any;
  @Input("status") status: any;

  requested: boolean = false;
  userList: any;
  durationInSeconds = 3;
  snackbarClass = "snackbarClass";
  usersRef: AngularFireList<User> = null;
  Status = "offline";
  filterOnData: any = [];
  screenShotDataList: any;
  showingErrorImage: any = false;
  timeAgo: true;
  startDate: any;
  endDate: any = new Date();
  userImageClass = 'avatar-img';
  userDataSubscription: Subscription;
  isDownloadAll: boolean;
  isDeleteAll: boolean;
  selectedImagesLength;
  allScreenShotsSelected = true
  constructor(
    private route: ActivatedRoute,
    private loginService: LoginService,
    private _projectService: ProjectService,
    private snackBar: MatSnackBar,
    private _change: ChangeDetectorRef,
    private db: AngularFireDatabase,
    private _sharedService: SharedService,
    private _messagingService: MessagingServiceService,
    private datePipe: DatePipe,
    private customtimeAgoPipe: CustomTimeAgoPipe,
    private http: HttpClient,
    private sweetAlertService: SweetAlertService,
    private timeConversionService: TimeConversionService
  ) {

    this.isAdmin = JSON.parse(localStorage.getItem('isAdmin')) || false
    _messagingService.showNotification();
    this.startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    );
    const sub = this.route.params.subscribe((user) => {
      this.userId = user.userId;
    });
    this.getstatus();
  }

  ngOnChanges(changes: SimpleChange) {

    this.isAdmin = JSON.parse(localStorage.getItem('isAdmin')) || false

    let change: any = changes;

    if (change.endDateFilter) {

      if (this.startDateFilter !== null && this.endDateFilter !== null) {
        // let newFilterData = this.filterScreenShots(
        //   this.startDateFilter,
        //   this.endDateFilter
        // );
        // this.userSSList = newFilterData;
      }
    }

    if (change.userDetails && change.userDetails.currentValue) {
      this.userId = change.userDetails.currentValue.id;
      this.user = change.userDetails.currentValue.name;
      this.getSSlistUser();
    }
  }
  onStartDateChange(event) {
    let date = event.value;
    this.startDate = date;
  }

  onEndDateChange(event) {
    let date = event.value;
    this.endDate = date;
    if (this.startDate && this.endDate) {
      this.pageSize = 25;
      this.offset = 1;
      if (this.EmpPaginator) {
        this.EmpPaginator.firstPage()
      }
      this.getSSlistUser();

    }
  }
  ngOnInit() {
    this.getUserList();
    this.getUserDetails();
    this.offset = this.EmpPaginator["_pageIndex"] + 1;
  }

  getAll(): AngularFireList<User> {
    return this.usersRef;
  }

  getUserList() {
    this.loading = true;
    this._projectService.getUserList(0, 0).subscribe((user: any) => {
      this.userList = user;
      this.loading = false;
    });
  }

  getUserfromUserList(userInfo) {
    let selectedUser = this.userList.filter((ele: any) => {
      return ele.id == parseInt(userInfo);
    });
    return selectedUser[0];
  }

  getUserName(userId) {
    let selectedUser = this.userList.filter((ele: any) => {
      if (ele.id == parseInt(userId)) {
        return ele.name;
      }
    });
    return selectedUser[0].name;
  }

  getUserDetails() {
    this._projectService.getEmployeeData(this.userId).subscribe((data: any) => {
    });
  }
  ngOnDestroy() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
    this._messagingService.resetData()
  }

  getstatus() {
    this.usersRef = this.db.list(this.dbPath);
    this.userDataSubscription = this._messagingService.getUserData().subscribe((users) => {
      users.forEach((user) => {
        if (user.id === this.userId) {
          this.Status = user.status === "online" ? "online" : "offline";
        }

        if (
          user.id === this.userId &&
          (this.offset === 0 || this.offset === 1) &&
          this.datePipe.transform(new Date(), "yyyy-MM-dd") === this.datePipe.transform(this.endDate, "yyyy-MM-dd")
        ) {
          this.processScreenshotData(user);
        }
      });
    });
  }

  processScreenshotData(user) {
    const screenshotTypes = [
      { key: "ssRequest", type: "admin-request" },
      { key: "ssPeriodic", type: "Periodic" },
      { key: "ssAppChanged", type: "app-changed" },
    ];

    screenshotTypes.forEach(({ key, type }) => {
      const screenshot = user[key];
      if (screenshot && !user.isreceive) {
        if (screenshot.createdAt) {
          screenshot.createdAt = this.timeConversionService.convertISTToLocal(screenshot.createdAt)
        }
        const jsonData = {
          large: screenshot.largeImg || screenshot.imgURL,
          thumbnail: screenshot.imgURL,
          captured_at: this.customtimeAgoPipe.transform(screenshot.createdAt),
          captured_time: screenshot.createdAt,
          user_id: user.id,
          type,
          app_name: screenshot.applicationName,
        };

        if (!this.userSSList.some((item) => JSON.stringify(item) === JSON.stringify(jsonData))) {
          this.userSSList.unshift(jsonData);
        }
      }
    });
  }

  getSSlistUser() {
    this.loading = true;
    this._projectService.getSingleSS(this.userId, this.pageSize, this.offset, this.datePipe.transform(this.startDate, "yyyy-MM-dd") + 'T00:00:00', this.datePipe.transform(this.endDate, "yyyy-MM-dd") + 'T23:59:59').subscribe(
      (res: any) => {
        this.userSSList = undefined;
        this.userSSList = [];
        if (res.length == 0) {
          this.loading = false;
          this.showingErrorImage = true;
        }
        let userData: any = res.body;
        for (let i = 0; i <= userData.length - 1; i++) {
          let imageData = userData[i];
          for (let key in imageData) {
            if (key != "_links") {

              if (imageData[key]['captured_at']) {
                imageData[key]['captured_at'] = this.timeConversionService.convertISTToLocal(imageData[key]['captured_at'])
              }

              if (moment().unix() - moment(imageData[key]['captured_at'], "YYYY-MM-DD HH:mm:ss").unix() < 86400) {
                imageData[key]['timeAgo'] = true;
              }
              else {
                imageData[key]['timeAgo'] = false;
              }

              imageData[key].captured_time = imageData[key].captured_at;
              imageData[key].captured_at = this.customtimeAgoPipe.transform(imageData[key].captured_at);
              imageData[key].id = key
              imageData[key].isSelected = true
              this.userSSList.push(imageData[key]);
              this.filterOnData.push(imageData[key]);
            }
          }
          for (let i = 0; i <= imageData.length; i++) {
          }
        }

        this.selectedImagesLength = this.userSSList.length
        this.loading = false;
        if (!this.userSSList.length) {
          this.loading = false;
          this.showingErrorImage = true;
        }
        this.requested = false;
        this.totalSS = res.headers.get('X-WP-Total');
      },
      (err) => {
        this.userSSList = [];
        this.loading = false;
        this.showingErrorImage = true;
      }
    );
  }
  displayUserName(id) {
    let resData = this.userList.filter((data: any) => {
      return data.id == id;
    });
    return resData.length ? resData[0].name : "";
  }
  compareDatetime(a, b) {
    return a.datetime - b.datetime;
  }

  getFileName(ss, index?) {
    // console.log("ss ======>", ss, index);
    if (ss && ss.path) ss = ss.path;
    let name = ss.split("/")[1];
    return `${name.split("-")[4]}:${name.split("-")[5]}  ${name.split("-")[1]
      }/${name.split("-")[2]}/${name.split("-")[3]}`;
  }

  getFileNameTime(ss) {
    // console.log("ss ======>", ss);
    if (ss && ss.path) ss = ss.path;
    let name = ss.split("/")[1];

    let date = `${name.split("-")[2]}/${name.split("-")[1]}/${name.split("-")[3]
      }`;

    let time = `${name.split("-")[4]}:${name.split("-")[5]}`;

    let timeStamp = new Date(`${date} ${time}`);

    return timeStamp;
  }

  requestScreenShot(userId) {
    let data = {
      appName: "Requesting Screenshot",
      name: this.user,
    };
    this._sharedService.showToast(data);
    this.db.database
      .ref(`${this.dbPath}`)
      .child(`${userId}`)
      .update({ ssRequest: null });
    this.requested = true;
  }

  removeSpecialSS() {
    if (this.userSSList[0].path) {
      let path = this.userSSList[0].path;
      this.userSSList.splice(0, 1);
      this.userSSList.unshift(path);
      const dup = JSON.parse(JSON.stringify(this.userSSList));
      this.userSSListDuplicate = dup;
    }
  }

  selectDate(event: any) {
    if (event.end) {
      let startDate = event.start._d;
      let endDate = event.end._d;
      let date = [];
      while (moment(startDate) <= moment(endDate)) {
        startDate = moment(startDate).utcOffset("+5:30").format("YYYY-MM-DD");
        let reverse = startDate.split("-").reverse().join("-");
        date.push(reverse);
        startDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");
      }
      this.filterScreenShot({ dateRange: date });
    }
  }
  filterScreenShot(params) {
    let filteredSS = [];
    _.forEach(this.userSSListDuplicate, (singleSS, ssIndex) => {
      _.forEach(params.dateRange, (singleDate, dateIndex) => {
        if (singleSS.includes(singleDate)) {
          filteredSS.push(singleSS);
        }
      });
    });

    this.userSSList = filteredSS;
    let i = 1;
    while (i <= Math.ceil(this.userSSList.length / 10)) {
      this.paginationArray.push(i);
      i++;
    }
  }

  clearDatePicker() {
    this.userSSList = this.userSSListDuplicate;
    let i = 1;
    while (i <= Math.ceil(this.userSSList.length / 10)) {
      this.paginationArray.push(i);
      i++;
    }
    $("#dateRangePicker").val(null);
  }

  handlePage(event) {
  }

  userName(event) {
    (event);
    this.user = event;
  }

  checkStatus(params) {
    if (params.user == this.userId && params.status) {
      this.userStatus = params.status;
    } else {
      this.userStatus = "offline";
    }
  }

  filterScreenShots(startDate: any, endDate: any) {
    let filteredData: any = [];
    const startDateTimestamp = new Date(startDate).getDate();
    const endDateTimestamp = new Date(endDate).getDate();
    filteredData = this.filterOnData.filter((ele) => {
      const capturedAtTimestamp = new Date(ele["captured_at"]).getDate();
      if (
        capturedAtTimestamp >= startDateTimestamp &&
        capturedAtTimestamp <= endDateTimestamp
      ) {
        return ele;
      }
    });
    return filteredData;
  }

  onPageChange(event) {
    this.pageSize = this.EmpPaginator["_pageSize"];
    this.offset = this.EmpPaginator["_pageIndex"] + 1;
    this.getSSlistUser();
  }

  convertLowerCase(str) {
    return str.toLowerCase()
  }

  trimName(name) {
    const trimmedName = name.length <= 20 ? name : name.slice(0, 20) + (name.length > 20 ? '...' : '');
    return trimmedName;
  }

  checkTimeForImage(time: string) {
    const inputTime = new Date(time);
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - inputTime.getTime();
    const timeDifferenceInMinutes = timeDifference / (1000 * 60);
    return timeDifferenceInMinutes > 10;
  }


  removeScreenShot(imageInfo) {
    const userName = this.getUserfromUserList(imageInfo.user_id)?.name || 'Unknown User';
    this.sweetAlertService
      .showScreenshotConfirmation(imageInfo, userName).then((result) => {
        if (result.isConfirmed) {
          const data = { "post_ids": [`${imageInfo.id}`] }
          this._projectService.removeScreenshots(data).subscribe((removed) => {
            this.sweetAlertService.showAlert('Deleted!', 'The screenshot has been removed.', 'success')
            this.getSSlistUser()
          }, (error) => {
            console.error(" Can not remove the screenshot")
          })
        }
      });
  }

  downloadScreenShot(imageInfo) {
    console.log(imageInfo)
    const imageUrl = imageInfo.large;
    this.http.get(imageUrl + '?cacheblock=true', { responseType: 'blob' }).subscribe((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${this.getUserfromUserList(imageInfo.user_id)?.name}_${imageInfo.captured_time}.png`; // Set the desired filename
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });
  }

  enableSelection(status?: string) {
    if (status == 'download') {
      this.allScreenShotsSelected = true
      this.onSelectAllScreenshotsChange(this.allScreenShotsSelected)
      this.isDownloadAll = true
    }
    else if (status === 'delete') {
      this.allScreenShotsSelected = true
      this.onSelectAllScreenshotsChange(this.allScreenShotsSelected)
      this.isDeleteAll = true
    }
    else {
      this.isDownloadAll = false
      this.isDeleteAll = false
      this.onSelectAllScreenshotsChange(this.allScreenShotsSelected)
    }
  }

  downloadAll() {
    const urls = []
    const times = []
    swal.fire({
      title: 'Download in Progress',
      html: 'Please wait while your file is being downloaded...',
      allowOutsideClick: false, // Prevent closing by clicking outside
      showConfirmButton: false, // Hide the confirm button
      didOpen: () => {
        swal.showLoading(); // Show loading spinner
      }
    });
    this.userSSList.forEach((photo) => {
      if (photo.isSelected && photo?.large) {
        urls.push(photo.large)
        times.push(photo.captured_time)
      }
    })
    this.downloadFilesAsZip(urls, this.getUserName(this.userSSList[0].user_id), times)
  }

  downloadFilesAsZip(urls: string[], zipFileName: string, times: string[]): void {
    const zip = new JSZip();
    const folder = zip.folder(zipFileName);
    const filePromises = urls.map((url, index) => {
      return this.http.get(url + '?cacheblock=true', { responseType: 'blob' }).toPromise().then((blob) => {
        const fileName = `${times[index]}.${url.split('.').pop()}`;
        console.log(blob)
        folder?.file(fileName, blob);
      });
    });

    Promise.all(filePromises).then(() => {
      zip.generateAsync({ type: 'blob' }).then((zipFile) => {
        swal.close(); // Close the loading alert
        saveAs(zipFile, `${zipFileName}.zip`);
        this.enableSelection()
      });
    });
  }

  removeAllImages() {
    let screenShotIds = []

    this.userSSList.forEach(element => {
      if (element.isSelected) {
        screenShotIds.push(element.id)
      }
    });

    const totalImages = screenShotIds.length;

    this.sweetAlertService
      .showRemoveAllImagesConfirmation(totalImages)
      .then((result) => {
        if (result.isConfirmed) {
          const data = { "post_ids": screenShotIds }
          this._projectService.removeScreenshots(data).subscribe((removed) => {
            this.sweetAlertService.showAlert('Deleted!', 'All The screenshots have been removed.', 'success')
            this.enableSelection()
            this.getSSlistUser()
          }, (error) => {
            console.error(" Can not remove the screenshot")
          })
        } else {
          console.log('Image removal canceled.');
        }
      });
  }

  selectImage(image, index) {
    if (this.userSSList[index].isSelected) {
      this.userSSList[index].isSelected = false
      this.selectedImagesLength = this.selectedImagesLength - 1
    }
    else {
      this.userSSList[index].isSelected = true
      this.selectedImagesLength = this.selectedImagesLength + 1
    }
  }

  onSelectAllScreenshotsChange(value: boolean): void {
    console.log('All screenshots selected:', value);
    if (value) {
      this.userSSList.forEach(element => {
        element.isSelected = true
      });
      this.selectedImagesLength = this.userSSList.length
    }
    else {
      this.userSSList.forEach(element => {
        element.isSelected = false
      });
      this.selectedImagesLength = 0
    }

  }
}
