<div class="banner" *ngIf="!expired">
  <quota-banner [DeleteEmployee]="deleteUSer"></quota-banner>
</div>
<div class="container-fluid">

  <div class="row employee-list-container">
    <div class="col s12 l5 xl5">
      <h1>Employee List</h1>
    </div>
    <div class="col s12 l7 xl7 employee-list-search-container">
      <div class="search-bar">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.0259 13.8478L18.5951 17.4162L17.4159 18.5953L13.8476 15.0262C12.5199 16.0905 10.8684 16.6694 9.16675 16.667C5.02675 16.667 1.66675 13.307 1.66675 9.16699C1.66675 5.02699 5.02675 1.66699 9.16675 1.66699C13.3067 1.66699 16.6667 5.02699 16.6667 9.16699C16.6692 10.8687 16.0903 12.5201 15.0259 13.8478ZM13.3542 13.2295C14.4118 12.1419 15.0025 10.684 15.0001 9.16699C15.0001 5.94366 12.3892 3.33366 9.16675 3.33366C5.94341 3.33366 3.33341 5.94366 3.33341 9.16699C3.33341 12.3895 5.94341 15.0003 9.16675 15.0003C10.6838 15.0027 12.1417 14.4121 13.2292 13.3545L13.3542 13.2295Z"
            fill="#B3B3B3" />
        </svg>

        <input matInput #searchInp (keyup)="searchEmployee(searchInp.value)" placeholder="Search here">
      </div>
      <span *ngIf="!expired"
        matTooltip="{{quota_remaining == 0 ? 'Upgrade your plan as there is no remaining user quota.' : ''}}">
        <button mat-raised-button [disabled]="!isTourActive() || quota_remaining == 0" class="employee-add-btn"
          (click)="openImportUserModal(importModal)"
          [ngStyle]="{'visibility':(isAdmin && !isQuota) || !isTourActive() ? 'visible' : 'hidden','display':(isAdmin && !isQuota) || !isTourActive() ? 'block' : 'none'}">
          <span class="material-symbols-outlined">
            group_add
          </span>
          Import Employees
        </button>
      </span>
      <span *ngIf="!expired"
        matTooltip="{{quota_remaining == 0 ? 'Upgrade your plan as there is no remaining user quota.' : ''}}">
        <button mat-raised-button [disabled]="!isTourActive() || quota_remaining == 0" class="employee-add-btn"
          (click)="openUserCreateModal(userModal)" tourAnchor="addNewEmployee"
          [ngStyle]="{'visibility':(isAdmin && !isQuota) || !isTourActive() ? 'visible' : 'hidden','display':(isAdmin && !isQuota) || !isTourActive() ? 'block' : 'none'}">
          <span class="material-symbols-outlined">
            person_add
          </span>
          New Employee
        </button>
      </span>
    </div>
  </div>
  <div class="row">
    <div class="s12 col">
      <div>
        <div class="row dropdown-row">
          <div class="col s12">

          </div>
        </div>
        <app-users-table [expired]="expired" [developerList]="developerList" [loadWaiter]="stopLoading"
          [modalRef]="userModal" (modalOpen)="openUserModal($event)" (deleteEmployeee)="deleteEmployee()"
          (sortingData)="sortData($event)" [showAction]="true"></app-users-table>
      </div>
      <div>
        <mat-paginator [length]="totalUsers" [pageSize]="25" [pageSizeOptions]="[25, 50, 100]" aria-label="Select page"
          (page)="onPaginateChange($event)" #EmpPaginator
          [ngStyle]="{'visibility':developerList && developerList.length ? 'visible' : 'hidden'}">
        </mat-paginator>
      </div>
    </div>
  </div>

</div>

<div #userModal class="modal">
  <app-create-user [isEditable]="{id: editUserId, isEdit: isUserEditable}"></app-create-user>
</div>

<div #importModal class="modal modal-import" style="width: 35vw !important;">
  <app-import-users></app-import-users>
</div>