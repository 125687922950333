import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root', // This makes the service available application-wide
})
export class TimeConversionService {
  constructor() { }

  /**
   * Converts an IST timestamp to the system's local date and time.
   * @param istTimestamp - The timestamp in IST as a string (e.g., "YYYY-MM-DD HH:mm:ss").
   * @returns Local time as a string in the system's format.
   */
  convertISTToLocal(istTimestamp: string): string {
    // const istOffsetInMinutes = 330; // IST is UTC+5:30
    // const utcDate = moment(istTimestamp, 'YYYY-MM-DD HH:mm:ss')
    //   .utcOffset(-istOffsetInMinutes); // Convert IST to UTC
    // console.log("utcDate.local()", utcDate.local())
    // return utcDate.local().format('YYYY-MM-DD HH:mm:ss');

    const istDate = moment(istTimestamp, 'YYYY-MM-DD HH:mm:ss');

    // Convert IST to UTC
    const utcDate = istDate.utcOffset('+05:30', true); // '+05:30' is the IST offset

    console.log("Check conversion", istTimestamp, utcDate.local().format('YYYY-MM-DD HH:mm:ss'))

    // Convert UTC to system's local time
    return utcDate.local().format('YYYY-MM-DD HH:mm:ss');
  }
}
