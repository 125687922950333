import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SharedService } from '../Services/sharedService';
import { ProjectService } from '../Services/project.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import swal from "sweetalert2";
import { passwordValidator } from '../password-validators';
import { MAT_DIALOG_DATA, matDialogAnimations, MatDialogRef } from '@angular/material/dialog';
import { colors } from '../colors';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent {


  resetPasswordForm: UntypedFormGroup;
  hide = true;
  hideConfirm: boolean = true;
  email = '';
  code = '';
  isDisable = false;
  isEmployee: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) public user: any, private dialogRef: MatDialogRef<any>, private _sharedServices: SharedService, private _projectService: ProjectService, private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute) {
    this.isEmployee = router.url == '/employees' || router.url == '/projects';
    console.log("is employee: ", this.isEmployee)
    if (!this.isEmployee)
      this._sharedServices.sideMenuSubject.next(false);
    this.route.queryParams.subscribe(params => {
      if (params['data']) {
        let data = JSON.parse(params['data'])
        this.email = data.email;
        this.code = data.code;
      }
    });
    this.resetPasswordForm = new UntypedFormGroup({
      password: new UntypedFormControl("", [Validators.required, Validators.minLength(8), Validators.maxLength(20), passwordValidator()]),
      confirmPassword: new UntypedFormControl("", [Validators.required]),
    }, { validators: this.passwordMatchValidator });

    if (this.isEmployee) {
      this.resetPasswordForm.addControl('email', new UntypedFormControl({ value: user.email, disabled: true }))
    }
  }

  passwordMatchValidator(form: UntypedFormGroup) {
    return form.get('password').value === form.get('confirmPassword').value ? null : { 'mismatch': true };
  }

  resetPassAction() {
    this.isDisable = true;

    if (!this.resetPasswordForm.valid || this.resetPasswordForm.get('password').value !=
      this.resetPasswordForm.get('confirmPassword').value) {
      this.isDisable = false;
      return
    } else {
      this
        ._projectService
        .resetPasword(this.resetPasswordForm.value.password, this.email, this.code).subscribe((user: any) => {
          swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Your password has been successfully reset.Please login to continue',
            timer: 2000,
            showConfirmButton: false,
          })
          this.router.navigate(["/reset-sucessfully"]);
          this.isDisable = false;
        }, err => {
          this.isDisable = false;
          this._snackBar.open(err.error.message, 'Undo', {
            duration: 3000
          })
        })
    }
  }

  resetUserPassword() {
    this.isDisable = true;
    if (!this.resetPasswordForm.valid || this.resetPasswordForm.get('password').value !=
      this.resetPasswordForm.get('confirmPassword').value) {
      this.isDisable = false;
      return
    }
    else {
      this.dialogRef.close();
      swal.fire({
        title: 'Are you sure?',
        text: 'If the user is already logged in, they will be logged out and required to log in again using the new password.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: colors.purple,
        cancelButtonColor: colors.red,
        cancelButtonText: 'No',
      }).then((result) => {
        console.log("User: ", this.user, result)
        if (result.isConfirmed) {
          this.user['password'] = this.resetPasswordForm.get('password').value
          console.log("this.user submitting form: ", this.user)

          this._projectService.resetUserPassword(this.user).subscribe((res) => {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'User password has been successfully reset.',
              timer: 2000,
              showConfirmButton: false
            })
            this.dialogRef.close();
          }, err => {
            this.isDisable = false;
            this._snackBar.open(err.error.message, 'Undo', {
              duration: 3000
            })
          })
        }
      });
    }
  }

  closeModal() {
    this.dialogRef.close()
  }
}
