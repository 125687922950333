import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Observable, Observer, Subscription } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
//import { Socket } from "ngx-socket-io";
import * as _ from "lodash";
import * as M from "materialize-css/dist/js/materialize";
import { LoginService } from "../../Services/login.service";
import { SharedService } from "../../Services/sharedService";
import { config } from "src/app/config";
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { ProjectService } from "src/app/Services/project.service";
import {
  AngularFireDatabase,
  AngularFireList,
} from "@angular/fire/compat/database";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { OuterSubscriber } from "rxjs/internal/OuterSubscriber";
import { MatPaginator } from "@angular/material/paginator";
import { MessagingServiceService } from "src/app/Services/messaging-service.service";
import { TourConfigService } from "src/app/Services/tour-config.service";
import { MatDialog } from "@angular/material/dialog";
import { ImportUsersComponent } from "../import-users/import-users.component";
import * as moment from "moment";
import { ChangeDetectorRef } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { CustomTimeAgoPipe } from "src/app/time-ago.pipe";
import { TimeConversionService } from 'src/app/Services/time-conversion.service'

declare var $;

class User {
  id: string;
  email: string;
  ssRequest: boolean;
  scRequestStart: boolean;
  scRequestStop: boolean;
  status: string;
  time: string;
  isreceive: boolean;
}
@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.css"],
})
export class UserListComponent implements OnInit {

  @ViewChild("EmpPaginator", { static: true }) EmpPaginator: MatPaginator;

  userDataSubscription1: Subscription;
  userDataSubscription2: Subscription;

  usersRef: AngularFireList<User> = null;
  searchText: string = "";
  dataSource = new MatTableDataSource<any>([]);
  TeamdataSource = new MatTableDataSource<any>([]);
  adminId;
  isViewMore = true;
  developerList;
  filteredList: any = [];
  loading = true;
  iswordpress = false;
  createUserForm: UntypedFormGroup;
  createTeamForm: UntypedFormGroup;
  /*Socket variables*/
  documents: Observable<string[]>;
  statusToUpdate: any;
  groupId: any;
  currentUser = JSON.parse(localStorage.getItem("logedInUser"));

  filePath = config.baseMediaUrl;
  status: any;
  devList: any;
  private _docSub: Subscription;
  allUsers: any;
  empName;
  empSlug;
  teamStatus;
  teamSlug;
  teamTitle;
  employeeId;
  isTeamCreate;
  teamSelectedId;
  stopLoading: any = false;
  isUserEditable: any;
  editUserId: any;
  page = 0;
  pageSize = 10;
  totalUser;
  quota_remaining = 0;
  availableUser;
  roles: any = [
    "Admin",
    "Employee"
  ]
  daysRemaining: number;
  private dbPath = localStorage.getItem('dbPath');
  allUserList;
  totalUsers = 0;
  isAdmin = false;
  isQuota = false;
  isTrialBanner = true;
  isExpiredBanner = true;
  quotaStatus = '';
  deleteUSer = false;
  expired: Boolean;

  @ViewChild('userModal') modalRef: ElementRef<HTMLInputElement>;
  constructor(
    private _loginService: LoginService,
    private _projectService: ProjectService,
    //private socket: Socket,
    private route: ActivatedRoute,
    private _router: Router,
    private _sharedService: SharedService,
    private db: AngularFireDatabase,
    private _messagingService: MessagingServiceService,
    private _tourService: TourConfigService,
    private dialog: MatDialog,
    private _cd: ChangeDetectorRef,
    private customtimeAgoPipe: CustomTimeAgoPipe,
    private timeConversionService: TimeConversionService
  ) {
    console.log("in constructor");
    if (sessionStorage.getItem('isTrialBanner')) {
      this.isTrialBanner = true;
      this.isExpiredBanner = true;
    }
    _messagingService.showNotification();
    this.getQuota();
    this.getUSer();
    this._projectService.EmployeeList.subscribe((value) => {
      // console.log("value", value);
      this.pageSize = this.EmpPaginator["_pageSize"];
      this.page = this.EmpPaginator["_pageIndex"] + 1;
      this.getQuota();
      this.getAllUserList().then(() => {
        this.updateDevList();
      })
      // this.getDevList();
      this.getUSer();
    });
    _projectService.dashboardTourActive.subscribe((value: any) => {

      this.getQuota();
      // this.getDevList();
      this.updateDevList();
      this.getUSer();
    })
    this.usersRef = this.db.list(this.dbPath);
    this.userDataSubscription1 = this._messagingService.getUserData().subscribe((users) => {
      users.forEach((o) => {
        // console.log('ooooooooooo', o);
        if (this.developerList) {
          console.log("id", o.id, this.developerList);
          let index = _.findIndex(this.developerList, function (obj: any) {
            return obj.id == o.id;
          });

          if (index > -1) {
            const isLogoutReason = this.checkLogoutReason(o.logoutReason?.time, o.lastOnlineAtTz);
            if (o.logoutReason && isLogoutReason) {
              this.developerList[index]["status"] = 'auto-logout';
              if (o.logoutReason?.reason == 'timeOrTimezoneChange') {
                this.developerList[index]["logoutReason"] = { 'reason': 'Time or Timezone Changed.', 'time': moment.utc(o.logoutReason?.time).local().format("YYYY-MM-DD HH:mm:ss") };
              }
            } else if (o.lastOnlineAtTz) {
              this.developerList[index]["status"] = this._sharedService.getUserCurrentStatus(o.lastOnlineAtTz, true);
            } else if (!o.lastOnlineAtTz && o.lastOnlineAt) {
              this.developerList[index]["status"] = this._sharedService.getUserCurrentStatus(o.lastOnlineAt, false);
            }
            else {
              this.developerList[index]["status"] = 'not-using';
            }

            // for application information about version and application type.
            if (o.appInfo) {
              if (o.appInfo.applicationType && o.appInfo.version) {
                o.appInfo.label = o.appInfo.applicationType.split("")[0].toUpperCase();
                o.appInfo.applicationType = o.appInfo.applicationType[0].toUpperCase() + o.appInfo.applicationType.slice(1);
                this.developerList[index]["appInfo"] = o.appInfo;
              }
            } else {
              if (this.developerList[index]["status"] != "not-using") {
                this.developerList[index]["appInfo"] = { version: "4.1.2" }
              } else {
                this.developerList[index]["appInfo"] = "";
              }
            }

            this.developerList[index]["status_type"] = this.developerList[index]["status"] == undefined ? 'Away' : this.developerList[index]["status"] == 'online' ? 'online' : 'offline';

            // this.developerList[index]["time"] = o.time;
            if (
              o.ssRequest != true &&
              o.ssRequest != false &&
              o.ssRequest != undefined &&
              o.ssRequest != null
            ) {
              this.developerList[index]["ssRequest"] = o.ssRequest["createdAt"];
            }
            this.filteredList = this.developerList;
          }
        }
      });
    });

    this.createUserForm = new UntypedFormGroup({
      userName: new UntypedFormControl("", [Validators.required]),
      userEmail: new UntypedFormControl("", [Validators.required]),
      password: new UntypedFormControl("", [Validators.required]),
      confirmPass: new UntypedFormControl("", [Validators.required]),
      roleList: new UntypedFormControl(this.roles, [Validators.required]),
    });

    this.createTeamForm = new UntypedFormGroup({
      status: new UntypedFormControl(""),
      title: new UntypedFormControl(""),
      slug: new UntypedFormControl(""),
      team_manager: new UntypedFormControl(""),
      team_lead: new UntypedFormControl(""),
      team_developer: new UntypedFormControl(""),
    });
    // this.socket.removeAllListeners();
    $(document).ready(function () {
      $(".fixed-action-btn").floatingActionButton();
      $(".tooltipped").tooltip();
    });

    //this.socket.emit("getStatus", { doc: "" });
    this.route.params.subscribe((user) => {
      this.groupId = user.groupId;
    });

    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.status = params.status;
      }
    });
    this.getAllUserList();
  }

  getAllUserList() {
    return new Promise((resolve, reject) => {
      this._projectService.getUserList(0, 0).subscribe((user: any) => {
        this.totalUsers = user.length;
        this.allUserList = user;
        this.allUserList.forEach((developer: any, index) => {

          if (developer.meta.last_screenshot) {
            developer.meta.last_screenshot = this.timeConversionService.convertISTToLocal(developer.meta.last_screenshot)
          }

          developer.meta.last_ss = developer.meta.last_screenshot;
          developer.meta.last_screenshot = this.customtimeAgoPipe.transform(developer.meta.last_screenshot);
        });
        resolve(true);
      })
    });
  }
  isTourActive() {
    if (localStorage.getItem('isTour')) {
      if (localStorage.getItem('isTour') == 'false') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  IncreaseLimitAction() {
    window.open('Https://supersee.io', '_blank')
  }
  quataUpdate() {
    if (this.isQuota) {
      return true;
    } else {
      return false;
    }
  }
  getQuota() {
    if (this.isTourActive()) {
      this
        ._projectService
        .getQuota().subscribe((user: any) => {
          if (user.quota == false || user.quota == 'false') {
            this.isQuota = true;
          } else {
            this.isQuota = false;
          }
        }, err => { })
    }
  }
  openUserCreateModal(elem: ElementRef) {

    if (this._sharedService.modalinstance) {
      this._sharedService.modalinstance = undefined;
    }
    this._sharedService.modalinstance = M.Modal.init(
      elem,
      {
        complete: () => {
          //  this.getAllUserList()
        },
      },
      100
    );
    this.isUserEditable = false;
    this.editUserId = null;

    this._sharedService.modalinstance.open({
      oncancel: () => {
      },
    });
    this._projectService.updateEditUserData({ isUserEditable: false, editUserId: null });

  }

  openImportUserModal(elem: ElementRef) {

    if (this._sharedService.modalinstance) {
      this._sharedService.modalinstance = undefined;
    }
    this._sharedService.modalinstance = M.Modal.init(
      elem,
      {
        complete: () => {
        },
      },
      100
    );

    this._sharedService.modalinstance.open({
      oncancel: () => {
      },
    });

    // const importDialog = this.dialog.open(ImportUsersComponent, {
    //   width: '660px'
    // })
  }

  openUserModal(data: any) {
    if (this._sharedService.modalinstance) {
      this._sharedService.modalinstance = undefined;
    }

    this.isUserEditable = true;
    this.editUserId = data.userId;

    this._sharedService.modalinstance = M.Modal.init(data.eleRef);
    this._sharedService.modalinstance.open();
    this._projectService.updateEditUserData({ isUserEditable: true, editUserId: data.userId });

  }

  deleteEmployee() {
    this.developerList = [];
    this.filteredList = this.developerList;
    this.pageSize = this.EmpPaginator["_pageSize"];
    this.page = this.EmpPaginator["_pageIndex"] + 1;
    this.deleteUSer = true;
    // this.getDevList();
    this.getAllUserList().then(() => {
      this.updateDevList();
    });
    this.getQuota();
    this.getUSer();
  }

  getAll(): AngularFireList<User> {
    return this.usersRef;
  }

  ngAfterViewInit() {
    this.pageSize = this.EmpPaginator["_pageSize"];
    this._cd.detectChanges();
  }

  ngAfterContentInit(): void {
    M.AutoInit();
  }

  async ngOnInit() {
    this.loading = true;

    this.pageSize = this.EmpPaginator["_pageSize"];
    this.page = this.EmpPaginator["_pageIndex"] + 1;
    // this.getDevList();
    this.getAllUserList().then(() => {
      this.updateDevList();
    });
  }

  filterUsers(event) {
    this.developerList = this.allUsers.filter((item) =>
      item.name.toLowerCase().includes(event.toLowerCase())
    );
    this.filteredList = this.developerList;

  }

  ngOnDestroy() {
    if (this.userDataSubscription1) {
      this.userDataSubscription1.unsubscribe();
    }
    if (this.userDataSubscription2) {
      this.userDataSubscription2.unsubscribe();
    }
    this._messagingService.resetData()
  }
  getDevList() {
    this.stopLoading = true;
    if (this.isTourActive()) {
      this._projectService
        .getUserList(this.page, this.pageSize || 25)
        .subscribe((user: any) => {
          this.stopLoading = false;
          this.developerList = [];
          this.developerList = user;
          this._projectService.updateEmployeeListData({ data: true })
          // if(this.developerList.length == 0){
          //   this.isViewMore = true;
          //   for(let i=0;i<=user.length-1;i++){
          //     this.developerList.push(user[i])
          //   }
          // }else{
          //   if(user.length != 0){
          //     this.isViewMore = true;
          //     for(let i=0;i<=user.length-1;i++){
          //       this.developerList.push(user[i])
          //     }
          //   }else{
          //     this.isViewMore = false;
          //   }
          // }
          this.filteredList = this.developerList;
          this.allUsers = user;
          // this.getAll().snapshotChanges().pipe(
          //   map(changes =>
          //     changes.map(c =>
          //       ({ key: c.payload.key, ...c.payload.val() })
          //     )
          //   )
          // )
          if (localStorage.getItem('dbPath')) {
            this.userDataSubscription2 = this._messagingService.getUserData().subscribe((users) => {
              users.forEach((o) => {
                // console.log("ooooooooooo", o);
                if (this.developerList) {
                  // console.log("id", o.id, this.developerList);

                  let index = _.findIndex(this.developerList, function (obj: any) {
                    return obj.id == o.id;
                  });

                  if (index > -1) {
                    const isLogoutReason = this.checkLogoutReason(o.logoutReason?.time, o.lastOnlineAtTz);
                    if (o.logoutReason && isLogoutReason) {
                      this.developerList[index]["status"] = 'auto-logout';
                      if (o.logoutReason?.reason == 'timeOrTimezoneChange') {
                        this.developerList[index]["logoutReason"] = { 'reason': 'Time or Timezone Changed.', 'time': moment.utc(o.logoutReason?.time).local().format("YYYY-MM-DD HH:mm:ss") };
                      }
                    } else if (o.lastOnlineAtTz) {
                      this.developerList[index]["status"] = this._sharedService.getUserCurrentStatus(o.lastOnlineAtTz, true);
                    } else if (!o.lastOnlineAtTz && o.lastOnlineAt) {
                      this.developerList[index]["status"] = this._sharedService.getUserCurrentStatus(o.lastOnlineAt, false);
                    } else {
                      this.developerList[index]["status"] = 'not-using';
                    }

                    // for application information about version and application type.
                    if (o.appInfo) {
                      if (o.appInfo.applicationType && o.appInfo.version) {
                        o.appInfo.label = o.appInfo.applicationType.split("")[0].toUpperCase();
                        o.appInfo.applicationType = o.appInfo.applicationType[0].toUpperCase() + o.appInfo.applicationType.slice(1);
                        this.developerList[index]["appInfo"] = o.appInfo;
                      }
                    } else {
                      if (this.developerList[index]["status"] != "not-using") {
                        this.developerList[index]["appInfo"] = { version: "4.1.2" }
                      } else {
                        this.developerList[index]["appInfo"] = "";
                      }
                    }

                    // this.developerList[index]["time"] = o.time;
                    if (
                      o.ssRequest != true &&
                      o.ssRequest != false &&
                      o.ssRequest != undefined &&
                      o.ssRequest != null
                    ) {
                      this.developerList[index]["ssRequest"] =
                        o.ssRequest["createdAt"];
                    }
                    this.filteredList = this.developerList;
                  }
                }
              });
              this.loading = false;
              //this.dataSource = new MatTableDataSource<any>(user);
            });
          }
          //  for(let i = 0;i <= this.developerList.length-1;i++){
          //     if(!this.developerList[i].status){
          //       this.developerList[i]["status"] = 'not-using';
          //     }
          //    }
        });
    } else {
      this.stopLoading = false;
      this.developerList = this._tourService.getEmployeeListjson();
    }
  }

  updateDevList() {
    console.log("all user list: ", this.allUserList)
    // this.stopLoading = true;
    if (this.isTourActive()) {
      this.developerList = this.allUserList?.slice((this.page - 1) * this.pageSize, (this.page) * this.pageSize);

      if (localStorage.getItem('dbPath')) {
        this.userDataSubscription2 = this._messagingService.getUserData().subscribe((users) => {
          users.forEach((o) => {
            // console.log("ooooooooooo", o);
            if (this.allUserList) {
              // console.log("id", o.id, this.developerList);

              let index = _.findIndex(this.allUserList, function (obj: any) {
                return obj.id == o.id;
              });

              if (index > -1) {
                const isLogoutReason = this.checkLogoutReason(o.logoutReason?.time, o.lastOnlineAtTz);
                if (o.logoutReason && isLogoutReason) {
                  this.developerList[index]["status"] = 'auto-logout';
                  if (o.logoutReason?.reason == 'timeOrTimezoneChange') {
                    this.developerList[index]["logoutReason"] = { 'reason': 'Time or Timezone Changed.', 'time': moment.utc(o.logoutReason?.time).local().format("YYYY-MM-DD HH:mm:ss") };
                  }
                } else if (o.lastOnlineAtTz) {
                  this.allUserList[index]["status"] = this._sharedService.getUserCurrentStatus(o.lastOnlineAtTz, true);
                } else if (!o.lastOnlineAtTz && o.lastOnlineAt) {
                  this.allUserList[index]["status"] = this._sharedService.getUserCurrentStatus(o.lastOnlineAt, false);
                } else {
                  this.allUserList[index]["status"] = 'not-using';
                }

                // for application information about version and application type.
                if (o.appInfo) {
                  if (o.appInfo.applicationType && o.appInfo.version) {
                    o.appInfo.label = o.appInfo.applicationType.split("")[0].toUpperCase();
                    o.appInfo.applicationType = o.appInfo.applicationType[0].toUpperCase() + o.appInfo.applicationType.slice(1);
                    this.allUserList[index]["appInfo"] = o.appInfo;
                  }
                } else {
                  if (this.allUserList[index]["status"] != "not-using") {
                    this.allUserList[index]["appInfo"] = { version: "4.1.2" }
                  } else {
                    this.allUserList[index]["appInfo"] = "";
                  }
                }

                if (
                  o.ssRequest != true &&
                  o.ssRequest != false &&
                  o.ssRequest != undefined &&
                  o.ssRequest != null
                ) {
                  this.allUserList[index]["ssRequest"] =
                    o.ssRequest["createdAt"];
                }
                // console.log("all user list 2: ", this.allUserList, this.page, this.pageSize, (this.page - 1) * this.pageSize, (this.page + 1) * this.pageSize)
                this.developerList = this.allUserList?.slice((this.page - 1) * this.pageSize, (this.page) * this.pageSize);
                this.filteredList = this.developerList;
                this.developerList.forEach((developer: any, index) => {
                  developer.meta.last_screenshot = this.customtimeAgoPipe.transform(developer.meta.last_screenshot);
                });
              }
            }
          });
          this.loading = false;
          //this.dataSource = new MatTableDataSource<any>(user);
        });
      }
      this.developerList.forEach((developer: any, index) => {
        if (!developer.appInfo) {
          developer.appInfo = "";
        }
      });
    } else {
      this.stopLoading = false;
      this.developerList = this._tourService.getEmployeeListjson();
    }
  }

  sortData(sort: Sort) {
    console.log("sort data: ", sort);
    if (this.allUserList.length > 0) {
      const data = this.allUserList.slice();
      if (!sort.active || sort.direction === "") {
        this.allUserList = data;
        return;
      }

      console.log(this.allUserList, "devloperlist")
      const sortedData = data.sort((a, b) => {
        const isAsc = sort.direction === "asc";
        switch (sort.active) {
          case "Employee Name":
            return this.compare(a.name.toLowerCase(), b.name.toLowerCase(), isAsc);
          case "status":
            const statusA: any = this.getStatusValue(a);
            const statusB: any = this.getStatusValue(b);
            return this.compare(statusA, statusB, isAsc);
          case 'screenshot':
            return this.compare(b.meta.last_ss, a.meta.last_ss, isAsc);
          case 'usedApps':
            console.log("")
            return this.compare(a.meta.last_application_name.toLowerCase(), b.meta.last_application_name.toLowerCase(), isAsc);
          case 'appInfo':
            const compareValue = this.getCompareValueOnVersion(a, b);
            return this.compare(compareValue[0], compareValue[1], isAsc);
          case 'role':
            return this.compare(a.role.length > 1 ? a.role[1] : a.role[0], b.role.length > 1 ? b.role[1] : b.role[0], isAsc);
          default:
            return 0;
        }
      });

      this.allUserList = sortedData;
      this.developerList = sortedData.slice((this.page - 1) * this.pageSize, (this.page) * this.pageSize);
      this.developerList.forEach((developer: any, index) => {
        developer.meta.last_screenshot = this.customtimeAgoPipe.transform(developer.meta.last_screenshot);
      });
      console.log("all users and developerList: ", sortedData, this.developerList, this.page, this.pageSize)

    }
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getStatusValue(user) {
    if (user.status == "online") {
      console.log('user--', user.status)
      return 'online';
    } else if (user.status == "auto-logout") {
      return 'auto-logout';
    }
    else if (!user.status) {
      return 'invite';
    } else {
      return 'offline';
    }
  }

  getCompareValueOnVersion(compareValueOne, compareValueTwo) {
    let compareValue = [];
    if (compareValueOne.appInfo) {
      let value = compareValueOne.appInfo?.applicationType ? `${compareValueOne.appInfo?.version}" "${compareValueOne.appInfo?.applicationType}` : `${compareValueOne.appInfo?.version}`
      compareValue.push(value);
    } else {
      compareValue.push("");
    }

    if (compareValueTwo.appInfo) {
      let value = compareValueTwo.appInfo?.applicationType ? `${compareValueTwo.appInfo?.version}" "${compareValueTwo.appInfo?.applicationType}` : `${compareValueTwo.appInfo?.version}`
      compareValue.push(value);
    } else {
      compareValue.push("");
    }

    return compareValue;
  }

  openCreateTeamModal(iscreate, tid) {
    this.isTeamCreate = iscreate;
    this.teamSelectedId = tid;
    $("#CreateTeam").show();
  }

  closeCreateTeamModal() {
    $("#CreateTeam").hide();
  }

  openEmpModal(eId) {
    this._projectService.getEmployeeData(eId).subscribe((data: any) => {
      this.empName = data.name;
      this.empSlug = data.slug;
      $("#detailModal").show();
    });
    // $("#basicExampleModal").modal('show');
  }

  closeEmpModal() {
    $("#detailModal").hide();
  }

  openeditEmployeemodal(eid) {
    this.employeeId = eid;
    $("#EditEmployee").show();
  }

  EditEmployee() {
    this.loading = true;
    if (!this.createUserForm.valid) {
      return;
    }
    this._projectService
      .editEmployee(this.employeeId, this.createUserForm.value)
      .subscribe((data) => {
        $("#EditEmployee").hide();
        this.pageSize = this.EmpPaginator["_pageSize"];
        this.page = this.EmpPaginator["_pageIndex"] + 1;
        // this.getDevList();

        this.getAllUserList().then(() => {
          this.updateDevList();
        });

      });
  }

  closeEditEmployeemodal() {
    $("#EditEmployee").hide();
  }
  getUSer() {
    if (this.isTourActive()) {
      let roleArr = [];
      this._projectService.getUser().subscribe((data: any) => {
        localStorage.setItem('user', JSON.stringify(data))
        let isExpired = this._sharedService.checkPlanExpired(data)
        console.log("isExpired", isExpired);
        this.expired = isExpired;
        // if (isExpired) {
        //   return this._router.navigateByUrl('/plan-expired');
        // }
        roleArr = data.role;
        let quotaStatus = data.meta.quota_status;
        this.quota_remaining = data.meta.quota_remaining;
        this.quota_remaining = this.quota_remaining <= 0 ? 0 : this.quota_remaining;
        this.quotaStatus = quotaStatus;
        // if (data.meta.quota_remaining != 'false' && data.meta.quota_remaining != false) {
        if (quotaStatus == 'active' || quotaStatus == 'Active') {
          if (this.expiringDate(data.meta.expires_at, data.meta.today_date) <= data.meta.adv_pay) {
            this.isTrialBanner = false;
            this.isExpiredBanner = true;
          }
        }
        // }
        if (quotaStatus == 'trial' || quotaStatus == 'Trial') {
          this.isTrialBanner = false;
          this.isExpiredBanner = true;
        }
        if (quotaStatus == 'trial-expired' || quotaStatus == 'Trial-expired' || quotaStatus == 'expired' || quotaStatus == 'Expired') {
          this.isTrialBanner = true;
          this.isExpiredBanner = false;
          this.isQuota = true;
        }
        if (sessionStorage.getItem('isTrialBanner')) {
          this.isTrialBanner = true;
          this.isExpiredBanner = true;
        }

        this.totalUser = data.meta.quota_allowed;
        this.availableUser = data.meta.quota_remaining == 'false' || data.meta.quota_remaining == false ? 0 : data.meta.quota_remaining;
        this.availableUser = this.availableUser < 0 ? 0 : this.availableUser;
        if (data.meta.expires_at) {
          this.getRemainingDate(data.meta.expires_at)
        }

        let adminRole = roleArr.filter((ele) => {
          return (ele.toLowerCase().includes("admin") || ele.toLowerCase().includes("company"));
        })
        if (adminRole.length == 0) {
          this.isAdmin = false;
        } else {
          this.isAdmin = true;
        }
      })
    } else {
      let roleArr = [];
      this._projectService.getUser().subscribe((data: any) => {
        localStorage.setItem('user', JSON.stringify(data))
        let isExpired = this._sharedService.checkPlanExpired(data)
        this.expired = isExpired;
        // if (isExpired) {
        //   return this._router.navigateByUrl('/plan-expired');
        // }
        roleArr = data.role;
        let adminRole = roleArr.filter((ele) => {
          return (ele.toLowerCase().includes("admin") || ele.toLowerCase().includes("company"));
        })
        if (adminRole.length == 0) {
          this.isAdmin = false;
        } else {
          this.isAdmin = true;
        }
      })
    }
  }

  // expiringDate(strDate) {
  //   const expirationDate = new Date(strDate);
  //   const timeDifference = expirationDate.getTime() - new Date().getTime();
  //   const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  //   let isExpirationNear = daysDifference < 15;
  //   // this.daysRemaining = Math.round(daysDifference);

  //   return isExpirationNear;
  // }

  expiringDate(strExpiredDate, strTodayDate) {
    const expirationDate = new Date(strExpiredDate);
    const timeDifference = expirationDate.getTime() - new Date(strTodayDate).getTime();
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    return daysDifference + 1;
  }

  getRemainingDate(strDate) {
    const expirationDate = new Date(strDate);
    const timeDifference = expirationDate.getTime() - new Date().getTime();
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    this.daysRemaining = Math.round(daysDifference);
  }
  getDeveloperList(params) {
    this._loginService.getUserList(params).subscribe(
      (users: any) => {

        users.developers.forEach((developer: any) => {
          developer["screenShotTime"] = developer.screenshot
            ? this.imageNameAndDate(developer.screenshot, "date")
            : null;
        });

        users.developers.sort((a, b) => a.screenShotTime - b.screenShotTime);
        this.allUsers = users.developers.reverse();
        this.developerList = JSON.parse(JSON.stringify(this.allUsers));
        this.developerList.forEach((user, index) => {
          if (user.userRole == "admin") {
            this.developerList.splice(index, 1);
          }
        });
        this.filteredList = this.developerList;
        this.loading = false;
        this.updateStatus(this.statusToUpdate);
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  getFirstName(name) {
    return name.split(" ")[0];
  }

  getLastName(name) {
    return name.split(" ")[1];
  }

  updateStatus(userToUpdate) {
    if (this.developerList) {
      _.forEach(userToUpdate, (id) => {

        let index = _.findIndex(this.developerList, function (o: any) {
          return o._id == id.user;
        });
        // console.log("index --------------->", this.developerList[index], index);
        if (index > -1 && id.status == "online") {
          this.developerList[index]["status"] = id.status;
        } else if (index > -1) {
          delete this.developerList[index]["status"];
        }
        this.filteredList = this.developerList;
      });
    }
  }

  editProject() {
    this._router.navigate(["/projects/add-project"], {
      queryParams: { groupId: this.groupId },
    });
  }

  checkTeamLead(dev) {
    // console.log("checkTeamLead", dev.name);
    return dev.teamLead.includes(this.groupId) ? true : false;
    // return true
  }

  getStatus(dev) {
    this._loginService.tickStatusChanged({
      userId: dev._id,
      status: dev.status,
    });
  }

  imageNameAndDate(image, type) {
    return this._sharedService.imageNameAndDate(image, type);
  }

  createTeam() {
    let formValue = this.createTeamForm.value;
    const team_managerArr: string[] = formValue.team_manager.split(",");
    let formatedTeam_manager: string = formValue.team_manager
      ? "~" + team_managerArr.join("~") + "~"
      : "";

    const team_leadArr: string[] = formValue.team_lead.split(",");
    let formatedTeam_lead: string = formValue.team_lead
      ? "~" + team_leadArr.join("~") + "~"
      : "";

    const team_developerArr: string[] = formValue.team_developer.split(",");
    let formatedTeam_developer: string = formValue.team_developer
      ? "~" + team_developerArr.join("~") + "~"
      : "";

    let teamData = {
      status: formValue.status,
      title: formValue.title,
      slug: formValue.slug,
      meta: {
        team_manager: formatedTeam_manager,
        team_lead: formatedTeam_lead,
        team_developer: formatedTeam_developer,
      },
    };

    if (this.isTeamCreate == true) {
      this._projectService.createTeamMember(teamData).subscribe((data) => {
        $("#CreateTeam").hide();
      });
    } else {

    }
  }

  openTeamDetailModal(tId) {
    this._projectService.getTeamDetail(tId).subscribe((data: any) => {
      $("#TeamdetailModal").show();
      //this.getTeam();
      this.teamSlug = data.slug;
      this.teamStatus = data.status;
      this.teamTitle = data.title.rendered;
    });
  }

  closeTeamDetailModal() {
    $("#TeamdetailModal").hide();
  }

  removeKeysWithUndefinedValues(obj: any): any {
    const newObj: any = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (value !== undefined && value !== "") {
          newObj[key] = value;
        }
      }
    }

    return newObj;
  }

  searchEmployee(val: any) {
    this.isViewMore = false;
    // this.developerList = this.filteredList;
    this.developerList = this.allUserList;
    let svalue = val.toUpperCase();
    this.developerList = this.developerList.filter((ele) => {
      return ele.name.toUpperCase().includes(svalue);
    });
    if (val == "") {
      this.isViewMore = true;
      // console.log("this._userData.length not");
      // this.developerList = this.filteredList;
      // this.getDevList();

      this.updateDevList();
    }
  }
  btnViewMoreAction() {
    this.page = this.page + 1;
    this.getDevList();
  }

  onPaginateChange(event) {

    this.pageSize = this.EmpPaginator["_pageSize"];
    this.page = this.EmpPaginator["_pageIndex"] + 1;
    this.updateDevList();
    //this.currentPage = event.pageIndex;
  }

  trialCloseBanner() {
    sessionStorage.setItem('isTrialBanner', 'false');
    this.isTrialBanner = true;
    this.isExpiredBanner = true;
  }

  checkLogoutReason(logoutTime: string, lastActiveTime: string) {
    console.log("logout reason checking: ", logoutTime, lastActiveTime);
    logoutTime = moment(logoutTime).format('YYYY-MM-DD HH:mm:ss');
    lastActiveTime = moment(lastActiveTime).format('YYYY-MM-DD HH:mm:ss');
    console.log("logout reason checked", logoutTime, "lastActiveTime", lastActiveTime, moment(logoutTime).isAfter(lastActiveTime));
    // return true if logout time is greater than last active time
    return moment(logoutTime).isAfter(lastActiveTime);
  }

}
