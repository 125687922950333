import { HttpResponse } from '@angular/common/http';
import { Component, ɵIS_HYDRATION_DOM_REUSE_ENABLED } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProjectService } from 'src/app/Services/project.service';
import { SharedService } from 'src/app/Services/sharedService';
import swal from 'sweetalert2';
import { config } from 'src/app/config';
import Papa from 'papaparse';

@Component({
  selector: 'app-import-users',
  templateUrl: './import-users.component.html',
  styleUrl: './import-users.component.css'
})
export class ImportUsersComponent {

  uploadCSVForm: UntypedFormGroup;
  fileHeaders = ['first_name', 'last_name', 'password', 'role']
  disabled: boolean = true;
  csvDisabled: boolean = false;
  headerError: boolean = false;
  downloadCsv: string = config.import_employee_sample;
  errorMessageForValidate: any = {
    noDataFound: "No employee data was found in the uploaded file. Please ensure the file contains data and follows the required format.",
    invalidFileType: "The uploaded file is not in CSV format. Please ensure you upload a CSV file."
  }
  headerErrorMsg: any = {
    message: "",
    hasError: false
  };
  noDataErrorMsg: any = {
    message: "",
    hasError: false
  };
  fileObjectToShow: any = {
    fileName: "",
    hasShowObject: false
  }

  constructor(
    private _projectService: ProjectService,
    private _sharedService: SharedService
  ) {
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.uploadCSVForm = new UntypedFormGroup({
      csv: new UntypedFormControl(''),
      sendEmail: new UntypedFormControl(false)
    })
  }

  uploadCSV(event: any): void {
    const file = event?.srcElement?.files?.[0] || event;

    if (!file) {
      console.error("No file found in event.");
      return;
    }

    this.uploadCSVForm.get('csv').setValue(file);

    this.headerErrorMsg = {
      message: "",
      hasError: false
    };
    this.noDataErrorMsg = {
      message: "",
      hasError: false
    };

    const fileName = file.name;
    if (!fileName.endsWith('.csv')) {
      this.headerErrorMsg = {
        message: this.errorMessageForValidate?.invalidFileType,
        hasError: true
      };
      this.fileObjectToShow = {
        fileName: file.name,
        hasShowObject: true,
      };
      return;
    }

    // Use PapaParse to process the CSV file
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileContent = fileReader.result as string;

      Papa.parse(fileContent, {
        // header: true, // Treat the first row as column headers
        skipEmptyLines: true, // Skip empty rows
        complete: (result) => {
          console.log("Parsed CSV:", result);
          const headers = result.data[0].map((header) => header.trim());
          let data = result.data.slice(1);
          data = data.filter(row => row.length >= this.fileHeaders.length);

          // Check for missing headers
          const missingHeaders = new Set(this.fileHeaders);
          headers.forEach((header) => {
            const title = header.trim();
            if (missingHeaders.has(title)) {
              missingHeaders.delete(title);
            }
          });

          if (missingHeaders.size > 0) {
            let errorMsg = "The uploaded file is missing the required columns: ";
            errorMsg += Array.from(missingHeaders).join(", ");
            this.disabled = true;
            this.headerErrorMsg = {
              message: errorMsg,
              hasError: true
            };
            this.fileObjectToShow = {
              fileName: file.name,
              hasShowObject: true,
              data: data.length
            };
          } else if (data.length === 0) {
            this.disabled = true;
            this.noDataErrorMsg = {
              message: this.errorMessageForValidate.noDataFound,
              hasError: true
            };
            this.fileObjectToShow = {
              fileName: file.name,
              hasShowObject: true,
              data: data.length
            };
          } else {
            this.disabled = false;
            console.log(data)
 
            const jsonArray = data.map(row => {
              const jsonObject = {};
              headers.forEach((header, index) => {
                jsonObject[header] = row[index];
              });
              return jsonObject;
            });

            this.fileObjectToShow = {
              fileName: file.name,
              hasShowObject: true,
              data: data.length,
              jsonArray
            };
          }
        },
        error: (err) => {
          console.error("Error parsing CSV:", err);
          this.headerErrorMsg = {
            message: "An error occurred while processing the file.",
            hasError: true
          };
        }
      });
    };
    fileReader.readAsText(file);
  }

  onDragOver(event: DragEvent) {
    event.preventDefault(); // Prevent default behavior (open as link for files)
    const dropZone = event.target as HTMLElement;
    dropZone.classList.add('drag-over'); // Optional: Add a CSS class to change appearance
  }

  onDragLeave(event: DragEvent) {
    const dropZone = event.target as HTMLElement;
    dropZone.classList.remove('drag-over'); // Optional: Remove the CSS class
  }

  onFileDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files?.length) {
      const file = files[0]; // Get the first file
      this.uploadCSV(file); // Call the existing upload logic
    }
  }

  modalClose() {
    if (this._sharedService.modalinstance) {
      this._sharedService.modalinstance.close();
    }
    // this.uploadCSVForm.get('csv').setValue('');
    this.uploadCSVForm.reset()
    this.disabled = true;
    this.csvDisabled = false;
    this.headerErrorMsg = {
      message: "",
      hasError: false
    };
    this.noDataErrorMsg = {
      message: "",
      hasError: false
    };
    this.fileObjectToShow = {
      fileName: "",
      hasShowObject: false
    }

  }

  importEmployees() {
    this.disabled = true;
    this.csvDisabled = true;
    this.uploadCSVForm.disable();
    // console.log(" OLD UPLOAD FILE REQUEST ",this.uploadCSVForm.value)
    // console.log(" New UPLOAD FILE REQUEST ", { data: this.fileObjectToShow.jsonArray, dataLenght: this.fileObjectToShow.data, fileNmae: this.fileObjectToShow.fileName } )
    
    const reqData: any = { data: this.fileObjectToShow.jsonArray, dataLenght: this.fileObjectToShow.data, fileNmae: this.fileObjectToShow.fileName }
    
    if(this.uploadCSVForm.value.sendEmail && this.uploadCSVForm.value.sendEmail == true){
      reqData.send_email = true
    }
    
    this._projectService.uploadCSVFile(reqData).subscribe((res: HttpResponse<any>) => {
      const contentDisposition = res?.headers?.get('content-disposition');
      const filename = contentDisposition?.match(/filename="(.+?)"/)?.[1] || 'ss-created-employees.csv';

      const resBody = res?.body;
      this.uploadCSVForm.enable();
      this.uploadCSVForm.reset();
      this.disabled = false;
      this.csvDisabled = false;
      const blob = new Blob([resBody?.csv_data], { type: 'text/csv' });
      const successfullImports = resBody?.success;
      const failedImports = resBody?.failed;


      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = filename;
      link.click();

      this._projectService.updateEmployeeList({})
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Employees Imported!',
        html: `
        <p style="margin-top: 0px;">Check employee status in the downloaded report.</p>
        <b>
          <span style="color: #21bc68">
            Success:  
          </span>
          <span style="margin-right: 20px">
            ${successfullImports}
          </span>
          <span style="color: #E84646">
            Failed: 
          </span>
          <span>
            ${failedImports}
          </span>
        </b>`,
        showConfirmButton: false,
        timer: 5000,
      }).then(() => {
        this.modalClose();
      })
    }, (error: any) => {
      this.disabled = false;
      this.csvDisabled = false;
      swal.fire({
        icon: 'error',
        title: 'Not Imported!',
        text: 'Employees Not Imported. Try Again!',
        timer: 3000,
      })
    });
  }

}
