<!-- <app-header></app-header> -->
<div class="container-fluid">
  <div class="row user-setting-container">
    <div class="col s12" *ngIf="!userId">
      <h1>Settings</h1>
      <!-- <h1>{{userName}} Settings <mat-slide-toggle color="primary" formControlName="ss_personalizeSettings" (change)="isPersonalizesToggleChange($event)" *ngIf="userId"></mat-slide-toggle></h1> -->
    </div>
    <!-- <div class="col s12 m6" style="text-align: right">
      <button mat-raised-button class="cancel-btn">Cancel</button>
      <button mat-raised-button class="save-btn" (click)="submitGlobalConfig()">
        Save Changes
      </button>
      
    </div> -->
  </div>
  <form [formGroup]="globalSettings">

    <div class="row user-setting-container" *ngIf="userId">
      <div class="col s12 m12 l12 xl12 personalizeSettingDiv">
        <h1 style="cursor: pointer;" [routerLink]="['/user-details/', userId]">{{userName}} settings </h1><span
          class="personalize-toggle"> <mat-slide-toggle color="primary" formControlName="ss_personalizeSettings"
            (change)="isPersonalizesToggleChange($event)"></mat-slide-toggle></span>
      </div>

    </div>
    <mat-card class="system-config-card" tourAnchor="CustomizeOptionSetting">
      <div class="row setting-row">
        <div class="col s8 m6">
          <h2>Application settings</h2>
        </div>
      </div>
      <div class="setting-section system-config">
        <div class="row" style="margin-bottom: 0px;">
          <div class="col m12 s12 l6" style="margin-bottom: 18px;">
            <div class="option ">
              <div class="row option-container">
                <div class="col s10 option-contain">
                  <h2 class="option-h2">Periodic screenshots</h2>
                  <p class="option-p">Take screenshots at fixed intervals</p>
                </div>
                <div class="col s2 toggle-contain">
                  <mat-slide-toggle color="primary" formControlName="ss_periodic"
                    (change)="isPeriodicToggleChange($event)"></mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
          <div class="col m12 s12 l6" style="margin-bottom: 18px;">
            <div class="option ">
              <div class="row option-container">
                <div class="col s10 option-contain">
                  <h2 class="option-h2">Switched app Screenshots</h2>
                  <p class="option-p">Take screenshots when apps switch</p>
                </div>
                <div class="col s2 toggle-contain">
                  <mat-slide-toggle color="primary" formControlName="ss_appchange"
                    (change)="isAppChangeToggleChange($event)"></mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-bottom: 0px;">
          <div class="col m12 s12 l6" style="margin-bottom: 18px;">
            <div class="option ">
              <div class="row option-container">
                <div class="col s10 option-contain">
                  <h2 class="option-h2">Webcam photos</h2>
                  <p class="option-p">Record screenshots from webcam</p>
                </div>
                <div class="col s2 toggle-contain">
                  <mat-slide-toggle color="primary" formControlName="ss_webcam"></mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
          <div class="col m12 s12 l6" style="margin-bottom: 18px;">
            <div class="option ">
              <div class="row option-container">
                <div class="col s10 option-contain">
                  <h2 class="option-h2">Silent version </h2>
                  <p class="option-p">Enable Silent Version</p>
                </div>
                <div class="col s2 toggle-contain">
                  <mat-slide-toggle color="primary" formControlName="ss_silent"></mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-bottom: 0px;">
          <div class="col m12 s12 l6" style="margin-bottom: 18px;">
            <div class="option">
              <div class="row option-container">
                <div class="col s10 option-contain">
                  <h2 class="option-h2">Strict Time Tracking</h2>
                  <p class="option-p">Logout user on Time/Timezone change</p>
                </div>
                <div class="col s2 toggle-contain">
                  <mat-slide-toggle color="primary" (change)="strictTimeWarning($event)"
                    formControlName="ss_strict_time_tracking"></mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
    <div>
      <mat-card class="system-config-card" tourAnchor="CustomizeSettingConfiguration" id="Intervals_section">
        <div class="row setting-row">
          <div class="col s12 m12">
            <h2>Interval settings</h2>
          </div>
          <!-- <div class="col s4 m6" style="display: flex; justify-content: end">
            <h4>Intervals</h4>
          </div> -->
        </div>
        <div class="setting-section system-config">
          <!-- <div class="row"> -->
          <div class="row" style="margin-bottom: 5px;">
            <div class="col s12 m4 input-box" style="margin-bottom: 18px;">
              <mat-form-field class="input_fields" appearance="outline">
                <mat-label>Periodic screenshots (HH:mm:ss)</mat-label>
                <input type="text" step="1" matInput #systemIdel placeholder="5"
                  formControlName="ss_screen_capture_time" hour12="false" />

                <span matSuffix mat-raised-button
                  matTooltip="Capture screen every specified time (e.g., '01:30:00') for user activity monitoring, providing periodic snapshots to the administrator."
                  #captureAtTooltip="matTooltip" (click)="captureAtTooltip.toggle()"><svg
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px" class="info_icon">
                    <path
                      d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z" />
                  </svg></span>
              </mat-form-field>
              <!-- <mat-error *ngIf="globalSettings.get('ss_screen_capture_time').errors && 
              globalSettings.get('ss_screen_capture_time').touched">
              Invalid time format (HH:mm:ss) in 24-hour notation.
            </mat-error> -->
              <span *ngIf="globalSettings.get('ss_screen_capture_time').errors && 
            globalSettings.get('ss_screen_capture_time').touched">
                <mat-hint style="color: rgb(255, 0, 0);"> Invalid time format (HH:mm:ss) in 24-hour notation.</mat-hint>
              </span>
              <span *ngIf="globalSettings.get('ss_screen_capture_time').hasError('invalidTime')">
                <mat-hint style="color: rgb(255, 0, 0);"> Minimum 1 minute required </mat-hint>
              </span>
            </div>
            <div class="col s12 m4 input-box" style="margin-bottom: 18px;">
              <mat-form-field class="input_fields" appearance="outline">
                <mat-label>System Idle After (HH:mm:ss)</mat-label>
                <input type="text" matInput #systemIdel placeholder="5" formControlName="ss_idle_time" step="1" />

                <span matSuffix mat-raised-button
                  matTooltip="Designate a user system as idle after a specified duration (e.g., '00:05:00') without mouse or keyboard activity, aiding administrators in managing and optimizing idle user sessions."
                  #idleTooltip="matTooltip" (click)="idleTooltip.toggle()"><svg xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50" width="50px" height="50px" class="info_icon">
                    <path
                      d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z" />
                  </svg></span>

              </mat-form-field>
              <!-- <mat-error *ngIf="globalSettings.get('ss_idle_time').errors && 
              globalSettings.get('ss_idle_time').touched">
              Invalid time format (HH:mm:ss) in 24-hour notation.
             </mat-error> -->
              <!-- <span *ngIf="globalSettings.get('ss_idle_time').valid">
                  <mat-hint>Note: Minutes</mat-hint>
                </span> -->
              <span *ngIf="globalSettings.get('ss_idle_time').errors && 
                globalSettings.get('ss_idle_time').touched">
                <mat-hint style="color: rgb(255, 0, 0);"> Invalid time format (HH:mm:ss) in 24-hour notation.</mat-hint>
              </span>
              <span *ngIf="globalSettings.get('ss_idle_time').hasError('invalidTime')">
                <mat-hint style="color: rgb(255, 0, 0);"> Minimum 1 minute required </mat-hint>
              </span>
              <!-- <span *ngIf="globalSettings.get('ss_idle_time').errors && 
              globalSettings.get('ss_idle_time').hasError('required')">
                <mat-error> System ideal time is required. </mat-error>
              </span> -->
            </div>
            <div class="col s12 m4 input-box" style="margin-bottom: 18px;">
              <mat-form-field class="input_fields" appearance="outline">
                <mat-label>Switched app Screenshots (HH:mm:ss)</mat-label>
                <input type="text" matInput step="1" formControlName="ss_appchange_interval" />


                <span matSuffix mat-raised-button
                  matTooltip="Capture screenshots on application changes at a set interval (e.g., '00:05:00'), enhancing monitoring by providing snapshots when significant application switches occur."
                  #intervalTooltip="matTooltip" (click)="intervalTooltip.toggle()"><svg
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px" class="info_icon">
                    <path
                      d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z" />
                  </svg></span>
                <!-- <mat-hint>Note: Minutes</mat-hint> -->
              </mat-form-field>
              <!-- <mat-error *ngIf="globalSettings.get('ss_appchange_interval').errors && 
              globalSettings.get('ss_appchange_interval').touched">
                    Invalid time format (HH:mm:ss) in 24-hour notation.
              </mat-error> -->
              <span *ngIf="globalSettings.get('ss_appchange_interval').errors && 
              globalSettings.get('ss_appchange_interval').touched">
                <mat-hint style="color: rgb(255, 0, 0);"> Invalid time format (HH:mm:ss) in 24-hour notation.</mat-hint>
              </span>
              <span *ngIf="globalSettings.get('ss_appchange_interval').hasError('invalidTime')">
                <mat-hint style="color: rgb(255, 0, 0);"> Minimum 1 minute required</mat-hint>
              </span>
            </div>
          </div>
          <!-- <div class="col s12 m6 input-box" style="margin-bottom: 18px;">
              <mat-form-field class="input_fields" appearance="outline">
                <mat-label>System Summary Time (HH:mm)</mat-label>
                <input type="time" matInput formControlName="ss_auto_send_summry_time" />
                <span matSuffix mat-raised-button
                  matTooltip="Receive a daily user activity summary at the specified time (e.g., '07:00 PM'), including clock-in/out times and productivity details, for effective monitoring and management."
                  #summaryTooltip="matTooltip" (click)="summaryTooltip.toggle()"><svg xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50" width="50px" height="50px" class="info_icon">
                    <path
                      d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z" />
                  </svg></span>
              </mat-form-field>
            </div> -->
        </div>
      </mat-card>
      <mat-card tourAnchor="CustomizeSettingLinks" id="discoard_section">
        <div class="row setting-row">
          <div class="col s12">
            <h2>Discord hooks</h2>
          </div>
        </div>
        <div class="setting-section">
          <div class="row">
            <div class="col s12 " style="margin-bottom: 18px;">
              <mat-form-field class="input_fields" appearance="outline">
                <mat-label>Capture screen and camera</mat-label>
                <input type="text" matInput #captureScreenUrl formControlName="ss_image_messages_discord_url" />
                <span matSuffix mat-raised-button
                  matTooltip="To activate, set a Discord Hook URL in this field. As an admin, receive real-time screenshots and webcam pictures when a user changes applications on their system. Additionally, configure periodic captures or request them on-demand."
                  #discordCapture="matTooltip" (click)="discordCapture.toggle()"><svg xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50" width="50px" height="50px" class="info_icon">
                    <path
                      d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z" />
                  </svg></span>
              </mat-form-field>
              <!-- <span *ngIf="globalSettings.get('ss_screen_capture_time').errors && 
          globalSettings.get('ss_screen_capture_time').hasError('required')">
            <mat-error> Capture screen and camera link is required. </mat-error>
          </span> -->
            </div>
            <div class="col s12 input-box" style="margin-bottom: 18px;">
              <mat-form-field class="input_fields" appearance="outline">
                <mat-label>Updates</mat-label>
                <input type="text" matInput #updatesUrl formControlName="ss_status_messages_discord_url" />
                <span matSuffix mat-raised-button
                  matTooltip="Set a Discord Hook URL in this field to receive notifications as an admin. Get insights into when a user Starts and Stops work, along with Idle periods. Keep track of user engagement for effective monitoring and management."
                  #discordUpdates="matTooltip" (click)="discordUpdates.toggle()"><svg xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50" width="50px" height="50px" class="info_icon">
                    <path
                      d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z" />
                  </svg></span>
              </mat-form-field>
              <!-- <span *ngIf="globalSettings.get('ss_screen_capture_time').valid">
            <mat-hint>Note: Minutes</mat-hint>
          </span> -->
              <!-- <span *ngIf="globalSettings.get('ss_screen_capture_time').errors && 
          globalSettings.get('ss_screen_capture_time').hasError('required')">
            <mat-error> Update link is required. </mat-error>
          </span> -->
            </div>
            <!-- <div class="col s12 input-box" style="margin-bottom: 18px;">
              <mat-form-field class="input_fields" appearance="outline">
                <mat-label>Summary</mat-label>
                <input type="text" matInput #summaryUrl formControlName="ss_summary_messages_discord_url" />
                <span matSuffix mat-raised-button
                  matTooltip="Capture screen every specified time (e.g., '01:30:00') for user activity monitoring, providing periodic snapshots to the administrator."
                  #discordSummary="matTooltip" (click)="discordSummary.toggle()"><svg
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px" class="info_icon">
                    <path
                      d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z" />
                  </svg></span>
              </mat-form-field>            
            </div> -->
          </div>
        </div>
      </mat-card>
      <!-- <mat-card>
        <div class="row setting-row">
          <div class="col s12">
            <h2>Summary settings</h2>
          </div>
        </div>
        <div class="setting-section">
          <div class="row" style="margin-bottom:auto;">
            <div class="col s12 m8" style="margin-bottom: 18px;">
              <mat-form-field class="input_fields" appearance="outline">
              <mat-label>Add email for get summary</mat-label>
              @if(searchData.length) {
                <mat-select formControlName="summary_Search" multiple style="text-transform: capitalize !important;">
                  @for (user of searchData; track user.id) {
                    <mat-option (click)="onGroupSelect()" [value]="user" style="text-transform: capitalize !important;">{{ user.email }}</mat-option>                    
                  }
                </mat-select>
              } @else {                
                <mat-select formControlName="summary_Search">
                  <mat-option>No employee available</mat-option>
                </mat-select>
              }                  
            </mat-form-field>
             <div class="row selectedSummaryData" *ngFor="let section of availableData">
               <div class="col s12">
                 <span class="col s12 m11">{{section.name}}</span>
                 <span class="col s12 m1"
                 (click)="deleteSummaryItem(section)"
                 [ngClass]="'online'"                  
                 class="material-symbols-outlined"
                 >delete</span>
               </div>
             </div>
             <div class="row selectedSummaryData" *ngFor="let section of addEmailArr">
              <div class="col s12">
                <span class="col s12 m11">{{section}}</span>
                <span class="col s12 m1"
                (click)="deleteSummaryAddEmailItem(section)"
                [ngClass]="'online'"                  
                class="material-symbols-outlined"
                >delete</span>
              </div>
            </div>
            </div>
            <div class="col" matTooltip="Add email" style="margin-top: 12px;cursor: pointer;" (click)="addNewEmail()">
              <span class="material-symbols-outlined">
                person_add
              </span>  
            </div>
            <div class="col s12 m3 input-box" style="margin-bottom: 18px;">
              <mat-form-field class="input_fields" appearance="outline">
                <mat-label>Summary time (HH:mm:ss)</mat-label>
                <input type="time" matInput step="1" formControlName="summary_Interval" />
                <span matSuffix mat-raised-button
                  matTooltip="Capture screenshots on application changes at a set interval (e.g., '00:05:00'), enhancing monitoring by providing snapshots when significant application switches occur."
                  #intervalTooltip="matTooltip" (click)="intervalTooltip.toggle()"><svg
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px" class="info_icon">
                    <path
                      d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z" />
                  </svg></span>
              </mat-form-field>

            </div>
          </div>
        </div>
      </mat-card> -->
    </div>
  </form>
  <div class="row">
    <div class="col s12 " style="text-align: right">
      <button mat-raised-button class="cancel-btn">Cancel</button>
      <button mat-raised-button class="save-btn" (click)="submitGlobalConfig()">
        Save Changes
      </button>

    </div>
  </div>
</div>
