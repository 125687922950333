<form [formGroup]="createUserForm">
  <div class="container-fluid">
    <div class="modal-content">
      <div class="row d-flex align-items-center creat-new-employee-container">
        <div class="col m10 s12 creat-new-employee-contain">
          <h1>
            {{ !isEditable.isEdit ? "Create new employee" : "Edit employee" }}
          </h1>
        </div>
        <div class="col m2 s12 creat-new-employee-btn-contain">
          <button class="btn  modal-close btn-flat creat-new-employee-btn" (click)="modalClose()">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
              <path
                d="M20.0002 3.8335C10.7835 3.8335 3.3335 11.2835 3.3335 20.5002C3.3335 29.7168 10.7835 37.1668 20.0002 37.1668C29.2168 37.1668 36.6668 29.7168 36.6668 20.5002C36.6668 11.2835 29.2168 3.8335 20.0002 3.8335ZM28.3335 26.4835L25.9835 28.8335L20.0002 22.8502L14.0168 28.8335L11.6668 26.4835L17.6502 20.5002L11.6668 14.5168L14.0168 12.1668L20.0002 18.1502L25.9835 12.1668L28.3335 14.5168L22.3502 20.5002L28.3335 26.4835Z"
                fill="#D9D9D9" />
            </svg>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div class="row mt-2">
          <div class="col s12" *ngIf="isEditable.isEdit" style="margin-bottom: 15px;">
            <mat-form-field class="mat-form" appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput [value]="userEmail" disabled />
            </mat-form-field>
          </div>
          <div class="col s12" style="margin-bottom: 15px;">
            <mat-form-field class="mat-form" appearance="outline">
              <mat-label>First name</mat-label>
              <input matInput formControlName="first_name" style="text-transform: capitalize !important;" />
            </mat-form-field>
            <mat-error
              *ngIf="createUserForm.get('first_name')?.hasError('required') && (createUserForm.get('first_name')?.dirty || createUserForm.get('first_name')?.touched)">
              First name is required
            </mat-error>
          </div>
          <div class="col s12" style="margin-bottom: 15px;">
            <mat-form-field class="mat-form" appearance="outline">
              <mat-label>Last name</mat-label>
              <input matInput formControlName="last_name" style="text-transform: capitalize !important;" />
            </mat-form-field>
            <mat-error
              *ngIf="createUserForm.get('last_name')?.hasError('required') && (createUserForm.get('last_name')?.dirty || createUserForm.get('last_name')?.touched)">
              Last name is required
            </mat-error>
          </div>
          <div class="col s12" style="margin-bottom: 15px;" *ngIf="!isEditable.isEdit">
            <mat-label style="margin-right: 15px;">Use a random email for the employee?</mat-label>
            <mat-slide-toggle color="primary" formControlName="isRandomEmail" (change)="onRandomEmailChange($event)"></mat-slide-toggle>
          </div>
          <div class="col s12" *ngIf="!isEditable.isEdit" style="margin-bottom: 15px;">
            <mat-form-field class="mat-form " appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput type="email" formControlName="email" />
              <mat-hint>example&#64;doamin.com</mat-hint>
            </mat-form-field>
            <mat-error
              *ngIf="createUserForm.get('email')?.hasError('required') && (createUserForm.get('email')?.dirty || createUserForm.get('email')?.touched)">
              Email is required
            </mat-error>
            <mat-error *ngIf="createUserForm.get('email')?.hasError('pattern')">
              Please enter valid email
            </mat-error>

          </div>
          <div class="col s12" *ngIf="!isEditable.isEdit" style="margin-bottom: 15px;">
            <mat-form-field class="mat-form" appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput formControlName="password" placeholder="" [type]="hide ? 'password' : 'text'"
                autocomplete="new-password" />
              <mat-hint>{{
                !isEditable.isEdit
                ? "Enter Minimum 6 character password"
                : "Enter password"
                }}</mat-hint>
              <button mat-icon-button matSuffix class="passBtn" (click)="hide = !hide" style="cursor: pointer">
                <mat-icon [matTooltip]="hide ? 'Unlock' : 'Lock'">
                  <span class="material-symbols-outlined">
                    {{ hide ? "visibility_off" : "visibility" }}
                  </span>
                </mat-icon>
              </button>
            </mat-form-field>
            <mat-error
              *ngIf="createUserForm.get('password')?.hasError('required') && (createUserForm.get('password')?.dirty || createUserForm.get('password')?.touched)">
              Please enter password.
            </mat-error>
            <mat-error *ngIf="createUserForm.get('password')?.hasError('password')">{{
              createUserForm.get('password')?.errors?.password }}</mat-error>
          </div>
          <div class="col s12" *ngIf="!isEditable.isEdit" style="margin-bottom: 15px;">
            <mat-form-field class="mat-form" appearance="outline">
              <mat-label>Confirm password</mat-label>
              <input matInput [type]="hideConfirm ? 'password' : 'text'" formControlName="confirmPassword" />
              <button mat-icon-button matSuffix class="passBtn" (click)="hideConfirm = !hideConfirm"
                style="cursor: pointer">
                <mat-icon [matTooltip]="hideConfirm ? 'Unlock' : 'Lock'">
                  <span class="material-symbols-outlined">
                    {{ hideConfirm ? "visibility_off" : "visibility" }}
                  </span>
                </mat-icon>
              </button>
            </mat-form-field>
            <mat-error
              *ngIf="
					createUserForm.get('confirmPassword')?.hasError('required') && (createUserForm.get('confirmPassword')?.dirty || createUserForm.get('confirmPassword')?.touched)">
              Please enter confirm password </mat-error>
            <mat-error
              *ngIf="createUserForm.get('confirmPassword')?.value?.length > 0 && createUserForm.get('password')?.value != createUserForm.get('confirmPassword')?.value  && (createUserForm.get('confirmPassword')?.dirty || createUserForm.get('confirmPassword')?.touched) && createUserForm.errors?.mismatch">
              Password does not match </mat-error>
          </div>
          <div class="col s12" style="margin-bottom: 15px;">
            <mat-form-field class="mat-form" appearance="outline">
              <mat-label>Role</mat-label>
              <mat-select formControlName="roles2">
                <mat-option value="employee">Employee</mat-option>
                <mat-option value="lead">Team lead</mat-option>
                <mat-option value="manager">Manager</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error
              *ngIf="createUserForm.get('roles2')?.hasError('required') && (createUserForm.get('roles2')?.dirty || createUserForm.get('roles2')?.touched)">
              Please select role
            </mat-error>
          </div>
          <div class="col s12" style="margin-bottom: 15px;" *ngIf="!isEditable.isEdit">
            <mat-label style="margin-right: 15px;">Send E-mail to Employees?</mat-label>
            <mat-slide-toggle color="primary" formControlName="send_email"></mat-slide-toggle>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row footer-container">
          <div class="col s12 footer-contain">
            <button mat-raised-button type="button" class="btn modal-close  cancel-btn" (click)="modalClose()">
              Cancel
            </button>
            <button mat-raised-button type="submit" *ngIf="!isEditable.isEdit"
              [disabled]="!createUserForm.valid|| isDisable" (click)="createNewUser()" class="btn createEmployee">
              Save
            </button>
            <button mat-raised-button *ngIf="isEditable.isEdit" type="button"
              [disabled]="!createUserForm.valid || !isResponseGet" (click)="EditNewUser()" style="margin-right: 28px"
              class="btn createEmployee">
              Save
            </button>
          </div>
        </div>

      </div>
    </div>

  </div>
</form>

<app-loader *ngIf="loading"></app-loader>